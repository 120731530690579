import restServiceHelper from 'src/utils/restServiceHelper';
import restService from './restService';

class NewsService {
  async createAsync(data) {
    const url = `administrator/news`;

    return restServiceHelper.requestAsync(
      restService.postAsync(url, data, {
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
      })
    );
  }

  async updateAsync(id, data) {
    const url = `administrator/news/${id}`;

    return restServiceHelper.requestAsync(restService.putAsync(url, data));
  }

  async deleteAsync(id) {
    const url = `administrator/news/${id}/delete`;

    return restServiceHelper.requestAsync(restService.putAsync(url));
  }

  async listAsync(page = 0, keyword = '', size = 5) {
    const url = `administrator/news?page=${page}&keyword=${keyword}&size=${size}`;

    return restServiceHelper.requestAsync(restService.getAsync(url));
  }
}

const newsService = new NewsService();

export default newsService;
