import { Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { fieldType } from 'src/constants';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PropTypes from 'prop-types';
import privateStore from 'src/stores/PrivateStore';
import DateTimePickerCustom from './DateTimePickerCustom';
import Select from './Select';

ControllerField.propTypes = {
  schema: PropTypes.object.isRequired,
  control: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  changeEditor: PropTypes.func,
  onChangeSelectExtra: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  indeterminate: PropTypes.bool,
  onChangeMultipleSelectTable: PropTypes.func,
  onChangeDateTimePicker: PropTypes.func
};

ControllerField.defaultProps = {
  changeEditor: () => null,
  handleCheckboxChange: () => null,
  onChangeSelectExtra: () => null,
  indeterminate: undefined,
  onChangeMultipleSelectTable: () => null,
  onChangeDateTimePicker: () => null
};

export default function ControllerField({
  schema,
  control,
  errors,
  changeEditor,
  handleCheckboxChange,
  onChangeSelectExtra,
  indeterminate,
  onChangeMultipleSelectTable,
  onChangeDateTimePicker,
  initialValueSelectUser,
  onCloseDateTimePicker
}) {
  const inputType = schema.type;

  switch (inputType) {
    case fieldType.TEXT:
      return (
        <Controller
          name={schema.name}
          control={control}
          render={({ field }) => (
            <Stack>
              {schema.label && (
                <Typography variant="text" style={{ width: 150 }}>{`${schema.label}`}</Typography>
              )}
              <TextField
                {...field}
                size="small"
                fullWidth
                style={{ marginBottom: '16px' }}
                error={Boolean(errors[schema.errorField])}
                {...schema.fieldProps}
                label={!schema.label ? schema.fieldProps.label || '' : ''}
                type="text"
              />
            </Stack>
          )}
        />
      );

    case fieldType.DATE_TIME_PICKER:
      return (
        <Controller
          name={schema.name}
          control={control}
          render={({ field }) => (
            <Stack style={{ marginBottom: '16px' }}>
              {schema.label && (
                <Typography variant="text" style={{ width: 150 }}>{`${schema.label}`}</Typography>
              )}
              <Stack direction="row">
                <DateTimePickerCustom
                  {...field}
                  value={field.value}
                  label={!schema.label ? schema.fieldProps.label || '' : ''}
                  error={Boolean(errors[schema.errorField])}
                  onChange={onChangeDateTimePicker}
                  {...schema.fieldProps}
                />

                <Checkbox
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                  label="Send now"
                  checked={field.value === null}
                  value={field.value === null}
                  onClick={(e) => onCloseDateTimePicker(e)}
                />
              </Stack>
            </Stack>
          )}
        />
      );
    case fieldType.MULTIPLE_SELECT_TABLE:
      return (
        <Controller
          name={schema.name}
          control={control}
          render={({ field }) =>
            schema.render(
              field,
              errors[schema.errorField],
              schema.fieldProps,
              schema.label,
              onChangeMultipleSelectTable,
              initialValueSelectUser
            )
          }
        />
      );

    case fieldType.CKEDITOR:
      return (
        <Controller
          name={schema.name}
          control={control}
          render={({ field }) => (
            <Stack style={{ marginBottom: '16px' }}>
              {schema.label && (
                <Typography variant="text" style={{ width: 150 }}>{`${schema.label}`}</Typography>
              )}
              <CKEditor
                {...field}
                editor={ClassicEditor}
                data={field.value}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle('height', '180px', editor.editing.view.document.getRoot());
                    writer.setStyle('overflow-y', 'auto', editor.editing.view.document.getRoot());
                  });
                }}
                config={{
                  toolbar: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'blockQuote',
                    'link',
                    'numberedList',
                    'bulletedList',
                    'insertTable',
                    'tableColumn',
                    'tableRow',
                    'mergeTableCells',
                    'mediaEmbed',
                    '|',
                    'undo',
                    'redo'
                  ]
                }}
                onChange={(_e, batch) => changeEditor(batch)}
                {...schema.fieldProps}
                error={Boolean(errors[schema.errorField])}
              />
            </Stack>
          )}
        />
      );
    case fieldType.CHECK_BOX:
      return (
        <Controller
          control={control}
          name={schema.name}
          render={({ field }) => (
            <Stack style={{ marginBottom: '16px' }}>
              {schema.label && (
                <Typography variant="text" style={{ width: 150 }}>{`${schema.label}`}</Typography>
              )}
              <FormControlLabel
                label={!schema.label ? schema.fieldProps.label || '' : ''}
                labelPlacement="start"
                style={{
                  marginBottom: '16px',
                  marginLeft: 0,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
                control={
                  <Checkbox
                    {...field}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                    onClick={(e) => handleCheckboxChange(e, schema.conditionDisable)}
                    checked={field.value}
                    indeterminate={schema.function ? indeterminate : null}
                    {...schema.fieldProps}
                  />
                }
              />
            </Stack>
          )}
        />
      );
    case fieldType.SELECT:
      return (
        <Controller
          name={schema.name}
          control={control}
          render={({ field }) => (
            <Stack style={{ marginBottom: '16px' }}>
              {schema.label && (
                <Typography variant="text" style={{ width: 150 }}>{`${schema.label}`}</Typography>
              )}
              {schema.selectExtra ? (
                <Select
                  {...field}
                  options={schema.options}
                  getValue={(opt) => opt.id}
                  getLabel={(opt) => opt.name}
                  style={{ marginBottom: '16px' }}
                  error={Boolean(errors[schema.errorField])}
                  onChange={(event) => onChangeSelectExtra(event, schema.key)}
                  {...schema.fieldProps}
                  label={!schema.label ? schema.fieldProps.label || '' : ''}
                />
              ) : (
                <Select
                  {...field}
                  options={schema.options}
                  getValue={(opt) => opt.id}
                  getLabel={(opt) => opt.name}
                  style={{ marginBottom: '16px' }}
                  error={Boolean(errors[schema.errorField])}
                  {...schema.fieldProps}
                  label={!schema.label ? schema.fieldProps.label || '' : ''}
                />
              )}
            </Stack>
          )}
        />
      );
    default:
      return null;
  }
}
