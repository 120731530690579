import { Box, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import Image from 'src/components/Image';
import LoadImage from 'src/components/LoadImage';

// required, label은 FormLabel컴포넌트로 직접 구현할것
export default function ImageControl({ name, control, accept, multiple, ...props }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box>
          {!field.value && (
            <TextField
              {...field}
              {...props}
              type="file"
              style={{ marginTop: '8px', width: '138px' }}
              inputProps={{ accept, multiple, style: { color: '#ffffff00' } }}
              InputLabelProps={{ focused: true }}
              onChange={(ev) => {
                const file = ev?.target?.files?.[0];
                field.onChange(file);
              }}
            />
          )}
          {field.value && typeof field.value === 'object' && (
            <LoadImage Component={Image} file={field.value} onRemove={() => field.onChange('')} />
          )}
          {field.value && typeof field.value !== 'object' && (
            <LoadImage Component={Image} src={field.value} onRemove={() => field.onChange('')} />
          )}
        </Box>
      )}
    />
  );
}
