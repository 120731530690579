import { promotionType } from 'src/constants';
import restServiceHelper from 'src/utils/restServiceHelper';
import restService from './restService';

class PromotionService {
  async createAsync(data) {
    const url = `administrator/promotion`;

    return restServiceHelper.requestAsync(
      restService.postAsync(url, data, { 'Content-Type': 'multipart/form-data' })
    );
  }

  async updateAsync(id, data) {
    const url = `administrator/promotion/${id}`;

    return restServiceHelper.requestAsync(restService.putAsync(url, data));
  }

  async deleteAsync(id) {
    const url = `administrator/promotion/${id}/delete`;

    return restServiceHelper.requestAsync(restService.putAsync(url));
  }

  async listAsync(page = 0, type = promotionType.MainPromotion, size = 5) {
    const url = `administrator/promotion?page=${page}&type=${type}&size=${size}`;

    return restServiceHelper.requestAsync(restService.getAsync(url));
  }
}

const promotionService = new PromotionService();

export default promotionService;
