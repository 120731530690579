import { FormControl, InputLabel, MenuItem, Select as MuiSelect, styled } from '@mui/material';
import { forwardRef } from 'react';

const Label = styled('label')(({ theme }) => ({
  color: theme.palette.grey[500]
}));

function Select(
  { options, getLabel, getValue, label, sx, required, error, placeholder, ...props },
  ref
) {
  return (
    <FormControl size="small" fullWidth sx={sx}>
      <InputLabel error={error}>{label}</InputLabel>
      <MuiSelect
        renderValue={
          placeholder
            ? (selected) => {
                if (selected) {
                  return options.filter((opt) => opt.id === selected)[0].name;
                }
                return <Label>{placeholder}</Label>;
              }
            : placeholder
        }
        label={label}
        ref={ref}
        error={error}
        displayEmpty
        {...props}
      >
        {options.map((opt) => (
          <MenuItem value={getValue(opt)} key={getValue(opt)}>
            {getLabel(opt)}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}

export default forwardRef(Select);
