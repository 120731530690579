import { LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { InputAdornment, TextField } from '@mui/material';
import React from 'react';
import Iconify from './Iconify';

export default function DateTimePickerCustom({ value, onChange, format, placeholder, ...props }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDateTimePicker
        {...props}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            sx={{
              input: {
                paddingTop: 1,
                paddingBottom: 1
              }
            }}
            InputProps={{
              ...params.inputProps,
              endAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="material-symbols:calendar-month-rounded"
                    sx={{ color: 'text.disabled' }}
                  />
                </InputAdornment>
              )
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              ...params.inputProps,
              placeholder: placeholder || ''
            }}
          />
        )}
        inputFormat={format}
        value={value}
        onChange={(newValue) => {
          onChange(newValue);
        }}
      />
    </LocalizationProvider>
  );
}
