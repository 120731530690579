/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useState } from 'react';
import { Button, Container, Dialog, Link, Slide, Stack, Tooltip, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import Table from 'src/components/Table';
import notificationService from 'src/services/notification';
import { fDateSql, fDateTime24, fDateTimeSuffixWithFormat } from 'src/utils/formatTime';
import { fieldType, GROUP_USER } from 'src/constants';
import { useConfirm } from 'material-ui-confirm';
import FormLayout from 'src/components/FormLayout';
import NotificationForm from './components/NotificationForm';
import SelectGroupUser from './components/SelectGroupUser';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const configs = (viewContentDetails) => [
  { key: 'title', name: 'Title' },
  {
    key: 'content',
    name: 'Content',
    render: (row) => (
      <Tooltip title="View content details">
        <Link component="button" underline="none" onClick={() => viewContentDetails(row)}>
          View content details
        </Link>
      </Tooltip>
    )
  },
  {
    key: 'groupSend',
    name: 'Users',
    cellProps: { width: '150px' }
  },
  {
    key: 'createdDate',
    name: 'Created date',
    cellProps: { width: '150px' },
    render: (row) => fDateTimeSuffixWithFormat(row.createdDate)
  },
  {
    key: 'sendDate',
    name: 'Sent Date',
    cellProps: { width: '150px' },
    render: (row) => fDateTimeSuffixWithFormat(row.sendDate)
  }
];

const moreFilterOptions = [
  {
    key: 'created',
    type: fieldType.DATE_RANG_PICKER,
    fieldProps: {
      placeholder: 'Created Date',
      fullWidth: true,
      maxDate: new Date()
    }
  },
  {
    key: 'send',
    type: fieldType.DATE_TIME_RANG_PICKER,
    fieldProps: {
      label: 'Send Date',
      placeholder: 'Send at',
      fullWidth: true
    }
  }
];

export default function Notification() {
  const [state, setState] = useState({
    page: 0,
    size: 5,
    items: [],
    rowCount: 0,
    filter: '',
    startCreated: '',
    endCreated: '',
    startSend: '',
    endSend: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [item, setItem] = useState(undefined);
  const [details, setDetails] = useState(undefined);
  const confirm = useConfirm();

  const { page, items, size, rowCount, filter, startCreated, endCreated, startSend, endSend } =
    state;

  const formSchema = [
    {
      key: 'title',
      name: 'title',
      type: fieldType.TEXT,
      errorField: 'title',
      label: 'Title',
      fieldProps: {
        type: 'text',
        required: true,
        size: 'small',
        fullWidth: true
      }
    },
    {
      key: 'sendDate',
      name: 'sendDate',
      type: fieldType.DATE_TIME_PICKER,
      errorField: 'sendDate',
      label: 'Schedule Date (Check to send now)',
      fieldProps: {
        required: true,
        size: 'small',
        fullWidth: true,
        format: 'dd/MM/yyyy p',
        minDateTime: item === null ? new Date() : null
      }
    },
    {
      key: 'content',
      name: 'content',
      type: fieldType.CKEDITOR,
      errorField: 'content',
      label: 'Content',
      fieldProps: {
        type: 'text',
        required: true,
        size: 'small',
        fullWidth: true
      }
    },

    {
      key: 'groupSend',
      name: 'groupSend',
      type: fieldType.SELECT,
      errorField: 'groupSend',
      selectExtra: true,
      label: 'Group User',
      fieldProps: {
        required: true,
        size: 'small',
        label: 'All User'
      },
      options: [
        { id: 'All', name: 'All' },
        { id: 'Customer', name: 'All Customer' },
        { id: 'Dealer', name: 'All Dealer' },
        { id: 'Some', name: 'Some One' }
      ]
    },
    {
      key: 'users',
      name: 'users',
      type: fieldType.MULTIPLE_SELECT_TABLE,
      controlShow: 'groupSend',
      choose: GROUP_USER[4],
      errorField: 'users',
      label: 'Users',
      fieldProps: {
        required: true,
        size: 'small',
        fullWidth: true
      },
      render: (
        field,
        errorField,
        fieldProps,
        label,
        onChangeMultipleSelectTable,
        initialValues
      ) => (
        <SelectGroupUser
          field={field}
          errorField={errorField}
          fieldProps={fieldProps}
          initialValues={initialValues}
          label={label}
          onChangeMultipleSelectTable={onChangeMultipleSelectTable}
        />
      )
    }
  ];

  const _getData = async () => {
    const params = { page, keyword: filter, size, startCreated, endCreated, startSend, endSend };
    const result = await notificationService.listAsync(params);

    if (result.data) {
      setState({ ...state, items: result.data.items, rowCount: result.data.totalRow });
    }
  };

  useEffect(() => {
    _getData();
  }, [page, size, filter, startCreated, endCreated, startSend, endSend]);

  const _handleChangeOrder = (orderBy, order) => {
    setState({ ...state, orderBy, order });
  };

  const _handleChangePage = (page) => {
    setState({ ...state, page });
  };

  const _handleChangeRowsPerPage = (rowsPerPage) => {
    setState({ ...state, size: rowsPerPage, page: 0 });
  };

  const _handleAddUser = () => {
    setItem(null);
  };

  const _handleEdit = (id) => {
    const item = items.find((x) => x.id === id);
    const cloneItem = JSON.parse(JSON.stringify(item));

    setItem(cloneItem);
  };

  const _handleCancel = (e, reason) => {
    if (((reason === 'escapeKeyDown' || reason === 'backdropClick') && !isSubmitting) || !reason) {
      setItem(undefined);
    }
  };

  const submit = async (data) => {
    setIsSubmitting(true);

    const formData = new FormData();
    const keys = Object.keys(data);

    keys.forEach((key) => {
      if (key === 'sendDate') {
        formData.append(key, data[key] ? fDateTime24(data[key]) : null);
      } else if (key === 'users') {
        const userClone = data[key] ? JSON.parse(data[key]) : [];
        formData.append(key, data[key] ? userClone.map(({ id }) => id) : []);
      } else {
        formData.append(key, data[key]);
      }
    });

    const result =
      item === null
        ? await notificationService.createAsync(formData)
        : await notificationService.updateAsync(data.id, formData);

    if (!result.error) {
      _getData();
      setItem(undefined);
    } else {
      const content =
        result.error?.data.message || 'An error has occurred. Please reload and try again.';
      confirm({
        title: <Typography fontWeight="bold">Error</Typography>,
        content,
        cancellationButtonProps: { style: { display: 'none' } }
      });
    }

    console.log(data);
    setIsSubmitting(false);
  };

  const _handleDelete = async (id) => {
    const result = await notificationService.deleteAsync(id);
    if (!result.error) {
      _getData();
    } else {
      const content =
        result.error?.data.message || 'An error has occurred. Please reload and try again.';
      confirm({
        title: <Typography fontWeight="bold">Error</Typography>,
        content,
        cancellationButtonProps: { style: { display: 'none' } }
      });
    }
  };

  const _handleChangeFilter = (value) => {
    setState({ ...state, filter: value, page: 0 });
  };

  const _handleChangeDate = (value, property) => {
    const uppercaseProperty = property.charAt(0).toUpperCase() + property.slice(1);
    if (Array.isArray(value)) {
      const startDate = fDateSql(value[0]);
      const endDate = value[1] === null ? fDateSql(new Date()) : fDateSql(value[1]);
      setState({
        ...state,
        [`start${uppercaseProperty}`]: startDate,
        [`end${uppercaseProperty}`]: endDate,
        page: 0
      });
    } else {
      const startValue = fDateTimeSuffixWithFormat(value.start, 'yyyy-MM-dd p');
      const endValue =
        value.end === null
          ? fDateTimeSuffixWithFormat(value.start, 'yyyy-MM-dd p')
          : fDateTimeSuffixWithFormat(value.end, 'yyyy-MM-dd p');
      setState({
        ...state,
        [`start${uppercaseProperty}`]: startValue,
        [`end${uppercaseProperty}`]: endValue,
        page: 0
      });
    }
  };

  const _viewContentDetails = (row) => {
    setDetails(row);
  };
  const _handleCloseDetails = () => {
    setDetails(undefined);
  };
  return (
    <Page title="Notification | Jessicar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Notification
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={_handleAddUser}
          >
            New notification
          </Button>
        </Stack>

        <Table
          searchPlaceholder="Search notification..."
          data={items}
          configs={configs(_viewContentDetails)}
          moreFilterOptions={moreFilterOptions}
          handleChangeDateRang={_handleChangeDate}
          handleChangeDateTimeRang={_handleChangeDate}
          rowCount={rowCount}
          page={page}
          rowsPerPage={size}
          onChangeOrder={_handleChangeOrder}
          searchKey={filter}
          onChangePage={_handleChangePage}
          onChangeRowsPerPage={_handleChangeRowsPerPage}
          onChangeSearchKey={_handleChangeFilter}
          onEditRow={_handleEdit}
          onDeleteRow={_handleDelete}
        />

        <Dialog
          fullWidth
          maxWidth="sm"
          open={item !== undefined}
          TransitionComponent={Transition}
          onClose={_handleCancel}
        >
          <NotificationForm
            initialValues={item}
            formSchema={formSchema}
            cancelButtonProps={{ disabled: isSubmitting }}
            submitButtonProps={{ loading: isSubmitting }}
            onSubmit={submit}
            onCancel={_handleCancel}
          />
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={details !== undefined}
          TransitionComponent={Transition}
          onClose={_handleCloseDetails}
        >
          <FormLayout
            title={details?.title || ''}
            cancelButtonProps={{ disabled: isSubmitting, onClick: _handleCloseDetails }}
            maxWidth="100%"
          >
            <Stack sx={{ padding: 2 }}>
              <div dangerouslySetInnerHTML={{ __html: details?.content || '' }} />
            </Stack>
          </FormLayout>
        </Dialog>
      </Container>
    </Page>
  );
}
