/**
 * @description converts generic javascript object to FormData
 */
export default function makeForm(data) {
  const f = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    f.append(key, value);
  });
  return f;
}
