/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Stack, Button, Container, Typography } from '@mui/material';
import Table from 'src/components/Table';
import RightDrawer from 'src/components/RightDrawer';
import Page from 'src/components/Page';
import Iconify from 'src/components/Iconify';
import newsService from 'src/services/news';
import Label from 'src/components/Label';
import NewsForm from './components/NewsForm';

const configs = [
  { key: 'title', name: 'Title' },
  {
    key: 'content',
    name: 'Content',
    cellProps: { width: '200px' },
    render: (row) => <div>{row.content.substring(0, 100)}...</div>
  },
  {
    key: 'type',
    name: 'Type',
    render: (row) => (
      <Label variant="ghost" color={row.type === 'Text' ? 'success' : 'info'}>
        {row.type}
      </Label>
    )
  },
  {
    key: 'numberView',
    name: 'Viewers'
  },
  {
    key: 'thumbnailUrl',
    name: 'Thumbnail'
  },
  {
    key: 'videoURL',
    name: 'Video'
  }
];

export default function News() {
  const [state, setState] = useState({
    page: 0,
    size: 5,
    items: [],
    rowCount: 0,
    filter: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [item, setItem] = useState(undefined);

  const { page, size, items, rowCount, filter } = state;

  const _getData = async () => {
    const result = await newsService.listAsync(page, filter, size);

    if (!result.error) {
      setState({ ...state, items: result.data.items, rowCount: result.data.totalRow });
    }
  };

  useEffect(() => {
    _getData();
  }, [page, size, filter]);

  const _handleChangeOrder = (orderBy, order) => {
    setState({ ...state, orderBy, order });
  };

  const _handleChangePage = (page) => {
    setState({ ...state, page });
  };

  const _handleChangeRowsPerPage = (rowsPerPage) => {
    setState({ ...state, size: rowsPerPage, page: 0 });
  };

  const _handleAddUser = () => {
    setItem(null);
  };

  const _handleEdit = (id) => {
    setItem(items.find((x) => x.id === id));
  };

  const _handleCancel = (e, reason) => {
    if (((reason === 'escapeKeyDown' || reason === 'backdropClick') && !isSubmitting) || !reason) {
      setItem(undefined);
    }
  };

  const _handleSubmit = async (data) => {
    setIsSubmitting(true);

    const formData = new FormData();
    const keys = Object.keys(data);

    keys.forEach((key) => {
      if (key === 'video') {
        if (typeof data.video === 'object' && data.video) {
          formData.append('video', data[key]);
        }
      } else if (key === 'thumbnail') {
        if (typeof data.thumbnail === 'object' && data.thumbnail) {
          formData.append('thumbnail', data[key]);
        }
      } else if (data[key]) {
        formData.append(key, data[key]);
      }
    });

    if (item === null) {
      const result = await newsService.createAsync(formData);

      if (!result.error) {
        _getData();
        setItem(undefined);
      }
    } else {
      const result = await newsService.updateAsync(data.id, formData);

      if (!result.error) {
        _getData();
        setItem(undefined);
      }
    }

    setIsSubmitting(false);
  };

  const _handleDelete = async (id) => {
    const result = await newsService.deleteAsync(id);
    if (!result.error) {
      _getData();
    }
  };

  const _handleChangeFilter = (value) => {
    setState({ ...state, filter: value, page: 0 });
  };

  return (
    <Page title="Car | Jessicar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            News
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={_handleAddUser}
          >
            New news
          </Button>
        </Stack>

        <Table
          searchPlaceholder="Search news..."
          data={items}
          configs={configs}
          rowCount={rowCount}
          page={page}
          rowsPerPage={size}
          onChangeOrder={_handleChangeOrder}
          searchKey={filter}
          onChangePage={_handleChangePage}
          onChangeRowsPerPage={_handleChangeRowsPerPage}
          onChangeSearchKey={_handleChangeFilter}
          onEditRow={_handleEdit}
          onDeleteRow={_handleDelete}
        />

        <RightDrawer open={item !== undefined} onClose={_handleCancel}>
          <NewsForm
            initialValues={item}
            cancelButtonProps={{ disabled: isSubmitting }}
            submitButtonProps={{ loading: isSubmitting }}
            onCancel={_handleCancel}
            onSubmit={_handleSubmit}
          />
        </RightDrawer>
      </Container>
    </Page>
  );
}
