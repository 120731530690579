// material
import { FormLabel, Stack, TextField } from '@mui/material';

export default function FilePicker({
  label,
  required,
  error,
  accept,
  multiple,
  onChange,
  ...props
}) {
  const _handleChange = (e) => {
    onChange(e);
    e.target.type = 'text';
    e.target.type = 'file';
  };
  return (
    <Stack direction="column" style={{ marginTop: '16px' }}>
      <FormLabel required={required} error={error}>
        {label}
      </FormLabel>
      <TextField
        {...props}
        type="file"
        style={{ marginTop: '8px', width: '138px' }}
        inputProps={{ accept, multiple, style: { color: '#ffffff00' } }}
        onChange={_handleChange}
        error={error}
        InputLabelProps={{ focused: true }}
        fullWidth
      />
    </Stack>
  );
}
