import { Autocomplete, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import carService from 'src/services/car';
import optionService from 'src/services/options';

export default function CarAutocomplete({ carId, onChange, ...props }) {
  const [cars, setCars] = useState([]);
  const [option, setOption] = useState(null);

  const _handleInputChange = debounce(async (e, v, reason) => {
    if (reason === 'input') {
      const result = await optionService.getTrim(0, v, 20);
      if (!result.error) {
        setCars(result.data.items.filter((x) => x.id !== v));
      }
    }
  }, 300);

  useEffect(() => {
    if (!carId) {
      setOption(null);
    } else {
      const _option = cars.find((x) => x.id === carId);
      if (_option) {
        setOption(_option);
      } else {
        carService
          .getTrimAsync(carId)
          .then((r) => {
            if (r.data) {
              setOption(r.data);
            } else {
              throw new Error('oke');
            }
          })
          .catch(() => setOption({ id: carId, name: 'Get later' }));
      }
    }
  }, [carId]);

  return (
    <Autocomplete
      value={option}
      onChange={onChange}
      isOptionEqualToValue={(opt, v) => opt.id === v.id}
      getOptionLabel={(opt) => opt.name}
      options={cars}
      onInputChange={_handleInputChange}
      renderInput={(params) => (
        <TextField {...params} {...props} size="small" value={params.value ?? ''} />
      )}
    />
  );
}
