import restServiceHelper from 'src/utils/restServiceHelper';
import restService from './restService';

class RankService {
  async createAsync(data) {
    const url = `administrator/rank`;

    return restServiceHelper.requestAsync(restService.postAsync(url, data));
  }

  async updateAsync(id, data) {
    const url = `administrator/rank/${id}`;

    return restServiceHelper.requestAsync(restService.putAsync(url, data));
  }

  async deleteAsync(id) {
    const url = `administrator/rank/${id}/delete`;

    return restServiceHelper.requestAsync(restService.putAsync(url));
  }

  async listAsync(page = 0, keyword = '', size = 5) {
    const url = `administrator/rank?page=${page}&keyword=${keyword}&size=${size}`;

    return restServiceHelper.requestAsync(restService.getAsync(url));
  }
}

const rankService = new RankService();

export default rankService;
