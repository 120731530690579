import { categoryType } from 'src/constants';
import restServiceHelper from 'src/utils/restServiceHelper';
import restService from './restService';

class CategoryService {
  async listAsync(filter = '', type = categoryType.FUEL_TYPE, page = 0, size = 5) {
    const url = `administrator/${type}?filter=${filter}&page=${page}&size=${size}`;

    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  async createAsync(data = {}, type = categoryType.FUEL_TYPE) {
    const url = `administrator/${type}`;

    return restServiceHelper.requestAsync(
      restService.postAsync(url, data, { 'Content-Type': 'multipart/form-data' })
    );
  }

  async updateAsync(id, data = {}, type = categoryType.FUEL_TYPE) {
    const url = `administrator/${type}/${id}`;

    return restServiceHelper.requestAsync(restService.putAsync(url, data));
  }

  async deleteAsync(id, type = categoryType.FUEL_TYPE) {
    const url = `administrator/${type}/${id}/delete`;
    return restServiceHelper.requestAsync(restService.putAsync(url));
  }
}

const categoryService = new CategoryService();

export default categoryService;
