import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import User from './pages/user';
import Car from './pages/car';
import Bid from './pages/bid';
import Brand from './pages/brand';
import NotFound from './pages/Page404';
import News from './pages/news';
import Notification from './pages/notification';
import Login from './pages/login';
import CarRank from './pages/car-rank';
import Setting from './pages/setting';
import Promotion from './pages/promotion';
import Category from './pages/category';
import AccountBrand from './pages/account-barnd';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Navigate to="/dashboard/car/car-model" /> },
        { path: 'user', element: <User /> },
        // { path: 'user/:mode', element: <User /> },
        { path: 'account-brand', element: <AccountBrand /> },
        { path: 'car/:mode', element: <Car /> },
        { path: 'brand', element: <Brand /> },
        { path: 'bid', element: <Bid /> },
        { path: 'news', element: <News /> },
        { path: 'notification', element: <Notification /> },
        { path: 'rank', element: <CarRank /> },
        { path: 'setting', element: <Setting /> },
        { path: 'promotion', element: <Promotion /> },
        { path: 'category/:category', element: <Category /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/car/car-model" /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
