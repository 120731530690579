/* eslint-disable react-hooks/exhaustive-deps */
import { Container, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FormLayout from 'src/components/FormLayout';
import Iconify from 'src/components/Iconify';
import Table from 'src/components/Table';
import { carOption, fieldType } from 'src/constants';
import carService from 'src/services/car';
import userService from 'src/services/user';
import { fDateSql, fDateTime } from 'src/utils/formatTime';

const configs = [
  {
    key: 'appId',
    name: 'App ID'
  },
  {
    key: 'nickName',
    name: 'Nick name'
  },
  {
    key: 'fullName',
    name: 'Name'
  },
  {
    key: 'registeredDate',
    name: 'Member Since',
    render: (row) => <p>{fDateTime(row.registeredDate)}</p>
  },
  {
    key: 'birthday',
    name: 'Birthday',
    render: (row) => <p>{row.birthday && fDateTime(row.birthday)}</p>
  },
  {
    key: 'customer',
    name: 'Customer',
    render: (row) => (
      <IconButton style={{ fontSize: 36 }} color={row.customer ? 'success' : 'error'}>
        <Iconify icon={row.customer ? 'material-symbols:check-small-rounded' : 'ic:round-close'} />
      </IconButton>
    )
  },
  {
    key: 'dealer',
    name: 'Dealer',
    render: (row) => (
      <IconButton style={{ fontSize: 36 }} color={row.dealer ? 'success' : 'error'}>
        <Iconify icon={row.dealer ? 'material-symbols:check-small-rounded' : 'ic:round-close'} />
      </IconButton>
    )
  },
  {
    key: 'authorized',
    name: 'Status',
    render: ({ authorized }) => (authorized ? 'Authorized' : 'Unauthorized')
  },
  {
    key: 'brand',
    name: 'Brands'
  }
];
export default function SelectUserArea({
  selects,
  cancelButtonProps,
  submitButtonProps,
  onCancel,
  onSubmit
}) {
  const [listBrand, setListBrand] = useState([]);
  const [listUserSelect, setListUserSelect] = useState([]);
  const [state, setState] = useState({
    page: 0,
    size: 5,
    items: [],
    rowCount: 0,
    filter: '',
    userType: 2,
    userStatus: 2,
    startMember: '',
    endMember: '',
    startBirth: '',
    endBirth: '',
    brandId: ''
  });

  const {
    page,
    size,
    items,
    rowCount,
    filter,
    userType,
    userStatus,
    startMember,
    endMember,
    startBirth,
    endBirth,
    brandId
  } = state;

  const _getData = async () => {
    const params = {
      page,
      keyword: filter,
      size,
      userType,
      userStatus,
      startMember,
      endMember,
      startBirth,
      endBirth,
      brandId
    };
    const result = await userService.listAsync(params);
    if (result.data) {
      setState({ ...state, items: result.data.items, rowCount: result.data.totalRow });
    }
  };

  const _getListBrand = async () => {
    const result = await carService.listOptionsAsync(carOption.brand);
    setListBrand(result.data);
  };

  useEffect(() => {
    _getData();
  }, [
    page,
    size,
    filter,
    userType,
    userStatus,
    startMember,
    endMember,
    startBirth,
    endBirth,
    brandId
  ]);

  useEffect(() => {
    _getListBrand();
    setListUserSelect(selects);
  }, []);

  const moreFilterOptions = [
    {
      key: 'userType',
      type: fieldType.SELECT,
      listOption: [
        { id: 2, name: 'Customer/Dealer' },
        { id: 0, name: 'Customer' },
        { id: 1, name: 'Dealer' }
      ],
      fieldProps: {
        placeholder: 'Filter by user type',
        fullWidth: true
      }
    },
    {
      key: 'userStatus',
      type: fieldType.SELECT,
      listOption: [
        { id: 2, name: 'Authorized/Unauthorized' },
        { id: 1, name: 'Authorized' },
        { id: 0, name: 'Unauthorized' }
      ],
      fieldProps: {
        placeholder: 'Filter by status',
        fullWidth: true
      }
    },
    {
      key: 'member',
      type: fieldType.DATE_RANG_PICKER,
      fieldProps: {
        placeholder: 'Member since',
        fullWidth: true,
        maxDate: new Date()
      }
    },
    {
      key: 'birth',
      type: fieldType.DATE_RANG_PICKER,
      fieldProps: {
        placeholder: 'Birthday',
        fullWidth: true,
        maxDate: new Date()
      }
    },
    {
      key: 'brandId',
      type: fieldType.SELECT,
      listOption: listBrand,
      fieldProps: {
        placeholder: 'Filter by brand',
        fullWidth: true
      }
    }
  ];

  const _handleChangeOrder = (orderBy, order) => {
    setState({ ...state, orderBy, order });
  };

  const _handleChangePage = (page) => {
    setState({ ...state, page });
  };

  const _handleChangeRowsPerPage = (rowsPerPage) => {
    setState({ ...state, size: rowsPerPage, page: 0 });
  };

  const _handleChangeFilter = (value) => {
    setState({ ...state, filter: value, page: 0 });
  };

  const _handleChangeSelect = (value, property) => {
    setState({ ...state, [property]: value, page: 0 });
  };

  const _handleChangeDate = (value, property) => {
    if (Array.isArray(value)) {
      const uppercaseProperty = property.charAt(0).toUpperCase() + property.slice(1);
      const startDate = fDateSql(value[0]);
      const endDate = value[1] === null ? fDateSql(new Date()) : fDateSql(value[1]);
      setState({
        ...state,
        [`start${uppercaseProperty}`]: startDate,
        [`end${uppercaseProperty}`]: endDate,
        page: 0
      });
    }
  };

  const _onSelect = (e, isItemSelected) => {
    const selectTemp = listUserSelect;
    setListUserSelect(
      isItemSelected ? selectTemp.filter((item) => item.id !== e.id) : selectTemp.concat([e])
    );
  };

  const _onSelectAll = (data, isSelectedAllThisPage) => {
    const selectTemp = listUserSelect;
    setListUserSelect(
      isSelectedAllThisPage
        ? selectTemp.filter((el) => data.findIndex((d) => d.id === el.id) === -1)
        : selectTemp.concat(data.filter((el) => selectTemp.findIndex((s) => s.id === el.id) === -1))
    );
  };

  return (
    <FormLayout
      title="Select User"
      maxWidth="100%"
      cancelButtonProps={{ ...cancelButtonProps, onClick: onCancel }}
      submitButtonProps={{
        ...submitButtonProps,
        text: 'Save',
        onClick: () => onSubmit(listUserSelect)
      }}
    >
      <Container sx={{ paddingY: '16px' }}>
        <Table
          searchPlaceholder="Search by ID/name..."
          moreFilterOptions={moreFilterOptions}
          handleChangeSelect={_handleChangeSelect}
          handleChangeDateRang={_handleChangeDate}
          data={items}
          configs={configs}
          rowCount={rowCount}
          page={page}
          rowsPerPage={size}
          onChangeOrder={_handleChangeOrder}
          searchKey={filter}
          onChangePage={_handleChangePage}
          onChangeRowsPerPage={_handleChangeRowsPerPage}
          onChangeSearchKey={_handleChangeFilter}
          action={false}
          checkable
          selected={listUserSelect}
          onSelect={_onSelect}
          onSelectAll={_onSelectAll}
          onDeleteAll={() => setListUserSelect([])}
        />
      </Container>
    </FormLayout>
  );
}
