import restServiceHelper from 'src/utils/restServiceHelper';
import restService from './restService';

class NotificationService {
  async createAsync(data) {
    const url = `administrator/notification`;

    return restServiceHelper.requestAsync(
      restService.postAsync(url, data, {
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
      })
    );
  }

  async updateAsync(id, data) {
    const url = `administrator/notification/${id}`;

    return restServiceHelper.requestAsync(restService.putAsync(url, data));
  }

  async deleteAsync(id) {
    const url = `administrator/notification/${id}/delete`;

    return restServiceHelper.requestAsync(restService.putAsync(url));
  }

  async listAsync(params) {
    const url = `administrator/notification`;

    return restServiceHelper.requestAsync(restService.getAsync(url, params));
  }

  async listUsersAsync(id) {
    const url = `administrator/notification/${id}/users`;
    return restServiceHelper.requestAsync(restService.getAsync(url));
  }
}

const notificationService = new NotificationService();

export default notificationService;
