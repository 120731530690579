import { useState, useEffect } from 'react';
import { getFullImageUrl } from 'src/utils/imageUrl';
import { IconButton } from '@mui/material';
import Iconify from 'src/components/Iconify';
import privateStore from 'src/stores/PrivateStore';

export default function LoadVideo({ style, onRemove, removeable = true, src, file, ...props }) {
  const [url, setUrl] = useState(file ? window.URL.createObjectURL(file) : undefined);

  useEffect(() => {
    if (src && src.search('http://') === -1 && src.search('https://') === -1) {
      const token = privateStore.getToken();

      fetch(getFullImageUrl(src), {
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
          'Access-Control-Allow-Headers': '*'
        }
      })
        .then((r) => r.blob())
        .then((d) => setUrl(window.URL.createObjectURL(d)))
        .catch((e) => {
          setUrl(null);
        });
    }
  }, [src]);

  useEffect(() => {
    if (file) {
      setUrl(window.URL.createObjectURL(file));
    }
  }, [file]);

  if (src && (src.search('http://') !== -1 || src.search('https://') !== -1)) {
    return (
      <div style={{ ...style, position: 'relative', width: '100px', height: '100px' }}>
        <video
          src={src}
          style={{ width: '100px', height: '100px', borderRadius: '8px' }}
          {...props}
        >
          <track kind="captions" />
        </video>
        {removeable && (
          <IconButton
            style={{ position: 'absolute', top: '0px', right: '0px', backgroundColor: 'white' }}
            color="error"
            onClick={onRemove}
            size="small"
          >
            <Iconify icon="ic:baseline-clear" />
          </IconButton>
        )}
      </div>
    );
  }

  if (url === undefined) {
    return null;
  }

  return (
    <div style={{ ...style, position: 'relative', width: '100px', height: '100px' }}>
      <video src={url} style={{ width: '100px', height: '100px', borderRadius: '8px' }} {...props}>
        <track kind="captions" />
      </video>
      {removeable && (
        <IconButton
          style={{ position: 'absolute', top: '0px', right: '0px', backgroundColor: 'white' }}
          color="error"
          onClick={onRemove}
          size="small"
        >
          <Iconify icon="ic:baseline-clear" />
        </IconButton>
      )}
    </div>
  );
}
