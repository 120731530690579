// import { Select } from '@mui/material';
import Select from 'src/components/Select';
import { Controller } from 'react-hook-form';

// use getValue, getLabel, onChange, options
export default function SelectControl({ name, control, ...props }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => <Select {...field} {...props} />}
    />
  );
}
