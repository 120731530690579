import restServiceHelper from 'src/utils/restServiceHelper';
import restService from './restService';

/**
 * @typedef {Object} AccountStatusCount
 * @property {number} count
 * @property {'registered' | 'verified' | 'subscription'} countType
 * @property {`${number}-${number}-${number}`} weekDay
 */

/**
 * @typedef {Object} AccountStatusCountResponse
 * @property {AccountStatusCount[]} data
 */

class UserService {
  async listAsync(params) {
    const url = `administrator/account`;
    return restServiceHelper.requestAsync(restService.getAsync(url, params));
  }

  async getAsync(id) {
    const url = `administrator/account/${id}`;
    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  async updateAsync(id, data) {
    const url = `administrator/account/${id}`;
    return restServiceHelper.requestAsync(restService.updateAsync(url, data));
  }

  /**
   * @return {Promise<AccountStatusCountResponse>}
   */
  async statusCountAsync() {
    const url = 'administrator/account/status/count';
    return restServiceHelper.requestAsync(restService.getAsync(url));
  }
}

const userService = new UserService();

export default userService;
