export const API_KEY =
  'bXXFJjqOMUiQuUcP2olwndheHy7KYCW4JiA8XKxzh/EzBR867mgitz22PdMvmsNp6b4eAY3zXtBbMxkjrXR1yjFOwxdKyzyIu3oqbS/rlUKoDLg25wKuIWoY30H5pvSOlQ9o6e9Q18QOLX6zf0uXjk9V/fU4SRGaWdUHXb/vl1NkZIL0DcdV4N9Ta03EhGDyjajSh4iH9x6gfnsLqEMm5x0MtMRkCmTbD8wXERfX/LvvD/W4S2/wpRCJiOeLKeRcFu8JBXssLM1799ByteHQWh6HdJ11/vKvqZSyb03xwdwiTpaw2fyXSdBZhMps+7qv0mTyhFNPT4rs3+XPKTOaoQ==,FQE17N2pJXJkrJGZuAuQWA==,QcxAKWy4eVVRvcUGctmmdfn1ehRKG7a2ly08NUvwrX+CMdQmoPzsI7wNIYZaYnVuNwRmClvkQXLGSq+Mw6RZVpxayF4tGI7MIrYM8JETpqWYtDMlJerlOQc4AZxzhJCWM4aWApRloCCz0c97PjPRJW1WnJ52KzowE3egg3fY3RUnMLVZA29QZWdWdeYjii7wZqLsRba7jeEgbblvR8EWpxYABJh8WUw65x88kgDlxUHpKoMFZEFkNhknJM+M12Ur';

export const bidType = {
  CashPrice: 'Cash Pricing bidding',
  ManufactureFinancialPrice: 'Manufacture financial price bidding',
  ThirdPartyFinancialPrice: 'Third party financial price bidding'
};
export const purchaseType = {
  Cash: 'Cash',
  Instalment: 'Instalment',
  Lease: 'Lease',
  Rental: 'Rental'
};

export const purchaseCondition = {
  TradeIn: 'Trade in',
  BranchPurchase: 'Brand purchase',
  UsedCarDisposalRequest: 'Used car disposal request'
};

export const contractPeriod = {
  3: '3 Months',
  6: '6 Months',
  12: '12 Months'
};

export const bidStatus = {
  REGISTER: 'Register',
  DEALING: 'Dealing',
  COMPLETE: 'Complete'
};

export const promotionType = {
  MainPromotion: 'MainPromotion',
  SubPromotion: 'SubPromotion'
};

export const categoryType = {
  FUEL_TYPE: 'fuel-type',
  VEHICLE_TYPE: 'car-type',
  //
  'fuel-type': {
    key: 'FUEL_TYPE',
    title: 'Fuel Type'
  },
  'car-type': {
    key: 'VEHICLE_TYPE',
    title: 'Vehicle Type'
  }
};

export const carMode = {
  CAR_MODEL: 'car-model',
  CAR_TRIM: 'car-trim',
  'car-model': {
    key: 'CAR_MODEL',
    title: 'Car Model'
  },
  'car-trim': {
    key: 'CAR_TRIM',
    title: 'Car Trim'
  }
};

export const carOption = {
  brand: 'manufacturer',
  model: 'car-model',
  fuelType: 'fuel-type',
  carType: 'car-type',
  trim: 'car-trim'
};

export const fieldType = {
  TEXT: 'TEXT',
  SELECT: 'SELECT',
  READONLY_TEXT: 'READONLY_TEXT',
  CHECK_BOX: 'CHECK_BOX',
  NUMBER: 'NUMBER',
  MULTIPLE_TEXT: 'MULTIPLE_TEXT',
  LABEL: 'LABEL',
  HIDDEN: 'HIDDEN',
  DATE_RANG_PICKER: 'DATE_RANG_PICKER',
  DATE_TIME_RANG_PICKER: 'DATE_TIME_RANG_PICKER',
  DATE_PICKER: 'DATE_PICKER',
  DATE_TIME_PICKER: 'DATE_TIME_PICKER',
  CONTROL_MULTIPLE_CHECKS: 'CONTROL_MULTIPLE_CHECKS',
  CKEDITOR: 'CKEDITOR',
  MULTIPLE_SELECT_TABLE: 'MULTIPLE_SELECT_TABLE',
  MULTIPLE_IMAGE: 'MULTIPLE_IMAGE'
};

export const checkBoxFunction = {
  FIX_RECOMMENDED_PRICES: 'fixed-recommitted-price'
};

export const businessCardStatus = {
  0: 'New',
  1: 'Accepted',
  2: 'Rejected',

  New: 0,
  Accepted: 1,
  Rejected: 2
};

export const subscriptionStatus = {
  0: 'Unsubscribed',
  1: 'Subscribed',

  UNSUBSCRIBED: '0',
  SUBSCRIBED: '1'
};

export const linkType = {
  Url: 1,
  Brand: 2,
  Trim: 3
};

export const GROUP_USER = {
  All: 1,
  Customer: 2,
  Dealer: 3,
  Some: 4,

  1: 'All',
  2: 'Customer',
  3: 'Dealer',
  4: 'Some'
};

/** @type {'local' | 'development' | 'production'} */
export const env = process.env.REACT_APP_ENV;
export const enableMsw = process.env.REACT_APP_ENABLE_MSW === 'true';
export const isLocal = env === 'local';
export const apiUrl = isLocal ? process.env.REACT_APP_API_URL_LOCAL : process.env.REACT_APP_API_URL;
export const cognitoClientId = isLocal
  ? process.env.REACT_APP_COGNITO_CLIENT_ID_LOCAL
  : process.env.REACT_APP_COGNITO_CLIENT_ID;

export const userPoolId = isLocal
  ? process.env.REACT_APP_USER_POOL_ID_LOCAL
  : process.env.REACT_APP_USER_POOL_ID;

console.log('-----env:', env);
console.log({ isLocal, apiUrl, cognitoClientId, userPoolId });
