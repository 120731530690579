import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { cognitoClientId, userPoolId } from 'src/constants';

export const getUserPool = () =>
  new CognitoUserPool({
    UserPoolId: userPoolId,
    ClientId: cognitoClientId
  });

export const isAuthenticated = () =>
  new Promise((success) => {
    const cognitoUser = getUserPool().getCurrentUser();

    if (!cognitoUser) {
      success({ isAuthenticated: false });
      return;
    }

    cognitoUser.getSession((err, session) => {
      if (err) {
        success({ isAuthenticated: false });
        return;
      }

      if (session.isValid()) {
        success({ isAuthenticated: true, session });
      } else {
        success({ isAuthenticated: false, session });
      }
    });
  });

export const conigtoLogout = () =>
  new Promise((success) => {
    const cognitoUser = getUserPool().getCurrentUser();
    if (cognitoUser !== null) {
      cognitoUser.signOut();
    }
    success();
  });

export const conigtoRefreshToken = () =>
  new Promise((success, reject) => {
    const cognitoUser = getUserPool().getCurrentUser();
    const token = cognitoUser.getSession().getRefreshToken();

    cognitoUser.refreshSession(token, (err, session) => {
      if (err) {
        reject(new Error('Cannot refresh token!'));
        return;
      }

      success(session);
    });
  });
