// material
import Image from 'src/components/Image';
import LoadImage from 'src/components/LoadImage';

export default function Thumbnail({ src, onRemove }) {
  const _handleRemove = () => onRemove(src);
  if (!src) {
    return null;
  }

  if (typeof src === 'object') {
    return (
      <LoadImage
        Component={Image}
        file={src}
        alt={src.name}
        onRemove={_handleRemove}
        style={{ margin: '10px' }}
        removeable={Boolean(onRemove)}
      />
    );
  }

  return (
    <LoadImage
      Component={Image}
      src={src}
      alt={src}
      onRemove={_handleRemove}
      style={{ margin: '10px' }}
      removeable={Boolean(onRemove)}
    />
  );
}
