import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';

export default function CheckboxControl({ control, name, label, ...props }) {
  if (!label) {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Checkbox
            {...field}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            //   onClick={field.onChange}
            checked={field.value}
            //   indeterminate={schema.function ? _indeterminate(fixPriceKeys) : null}
            {...props}
          />
        )}
      />
    );
  }
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControlLabel
          label={label}
          labelPlacement="start"
          style={{
            marginBottom: '16px',
            marginLeft: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
          }}
          control={
            <Checkbox
              {...field}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
              //   onClick={field.onChange}
              checked={field.value}
              //   indeterminate={schema.function ? _indeterminate(fixPriceKeys) : null}
              {...props}
            />
          }
        />
      )}
    />
  );
}
