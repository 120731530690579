import { useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Typography } from '@mui/material';
// components
import { LoginForm } from 'src/sections/authentication/login';
import { useContext } from 'react';
import { AuthContext } from 'src/stores/AuthContext';
import Page from '../../components/Page';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function Login() {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const _handleSuccess = (result) => {
    login(result);
    navigate('/dashboard');
  };

  return (
    <RootStyle title="Login | Minimal-UI">
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h3" gutterBottom>
              Sign in to Jessicar
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>

          <LoginForm onSuccess={_handleSuccess} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
