import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { conigtoLogout, isAuthenticated } from 'src/services/cognitoService';
import privateStore from './PrivateStore';

const initialState = {
  checked: false,
  authenticated: false,
  email: '',
  name: '',
  groups: [],
  avatar: ''
};

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [state, setState] = useState(initialState);

  const _checkToken = async () => {
    try {
      const response = await isAuthenticated();

      if (response.isAuthenticated) {
        login(response.session);
      } else {
        throw new Error('Need to login!');
      }
    } catch (error) {
      logout();
    }
  };

  useEffect(() => {
    _checkToken();
  }, []);

  const login = useCallback(
    (session) => {
      console.log('logging in...');
      const token = session.getAccessToken().getJwtToken();
      const { payload } = session.getIdToken();
      const authInfo = {
        email: payload.email,
        name: `${payload.given_name} ${payload.family_name}`,
        groups: payload['cognito:groups']
      };

      privateStore.setToken(token);
      setState({ ...state, ...authInfo, authenticated: true, checked: true });
    },
    [state]
  );

  const logout = useCallback(() => {
    privateStore.setToken(null);
    conigtoLogout();
    setState({ ...initialState, checked: true });
  }, [state]);

  const value = useMemo(() => ({ ...state, login, logout }), [state]);

  return state.checked && <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { AuthProvider, AuthContext };
