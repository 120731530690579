import { rest } from 'msw';

export default [
  rest.get('http://localhost:9000/api/administrator/account', async (req, res, ctx) => {
    const userType = req.url.searchParams.get('userType');
    if (userType === '1')
      return res(
        ctx.json({
          items: [
            {
              fullName: 'ali hong',
              appId: 'CWIBVK3aJiKc',
              email: null,
              brand: 'Benz',
              nickName: null,
              grade: null,
              point: 0,
              companyName: null,
              dealerAddress: null,
              rank: null,
              registeredDate: '2022-11-20T07:52:28Z',
              verifiedDate: '2022-11-20T07:52:28Z',
              birthday: null,
              customer: 1,
              dealer: 1,
              phoneNumber: null,
              authorized: 1,
              id: '53f89dfa-6a1c-49cf-acc5-bf7d61e4a4fa',
              address: '12'
            },
            {
              fullName: 'admin dealer01',
              appId: 'K74OZEXbDcG9',
              email: null,
              brand: 'Benz,BMW,Audi',
              nickName: null,
              grade: null,
              point: 0,
              companyName: null,
              dealerAddress: null,
              rank: null,
              registeredDate: '2022-11-09T05:35:56Z',
              verifiedDate: '2022-11-09T05:35:56Z',
              birthday: null,
              customer: 1,
              dealer: 1,
              phoneNumber: null,
              authorized: 1,
              id: '78b1c204-c8bf-4085-aac8-915000d35413',
              address: null
            },
            {
              fullName: 'ChauDealer Tran',
              appId: 'pH3ao2qjrZqc',
              email: null,
              brand: 'Audi,Benz',
              nickName: null,
              grade: null,
              point: 0,
              companyName: null,
              dealerAddress: null,
              rank: null,
              registeredDate: '2022-11-08T11:35:47Z',
              verifiedDate: '2022-11-08T11:35:47Z',
              birthday: null,
              customer: 1,
              dealer: 1,
              phoneNumber: null,
              authorized: 1,
              id: '6171f2f1-4237-449c-a9d3-a252e91d1434',
              address: null
            }
          ],
          page: 0,
          pageSize: 5,
          totalRow: 3,
          totalPage: 1
        })
      );
    return res(
      ctx.json({
        items: [
          {
            fullName: 'q q',
            appId: 'ELB5tOZUXU6a',
            email: null,
            brand: null,
            nickName: 'q',
            grade: null,
            point: 0,
            companyName: null,
            dealerAddress: null,
            rank: null,
            registeredDate: '2023-02-17T10:20:07Z',
            verifiedDate: '2023-02-17T10:20:07Z',
            birthday: null,
            customer: 1,
            dealer: 0,
            phoneNumber: null,
            authorized: 1,
            id: '9668a9cf-b7ed-4f53-b3fc-b8b47c117764',
            address: null
          },
          {
            fullName: '알리이 알',
            appId: 'BYYp0a7bEdeb',
            email: null,
            brand: null,
            nickName: '알리이이',
            grade: null,
            point: 0,
            companyName: null,
            dealerAddress: null,
            rank: null,
            registeredDate: '2023-02-17T10:18:30Z',
            verifiedDate: '2023-02-17T10:18:30Z',
            birthday: null,
            customer: 1,
            dealer: 0,
            phoneNumber: null,
            authorized: 1,
            id: '58bd8bde-4431-45e0-93b1-14d18e81aecf',
            address: null
          },
          {
            fullName: '홍태진 홍',
            appId: 'v63YH5RP5Fdp',
            email: null,
            brand: null,
            nickName: '알리이',
            grade: null,
            point: 0,
            companyName: null,
            dealerAddress: null,
            rank: null,
            registeredDate: '2023-02-17T10:16:33Z',
            verifiedDate: '2023-02-17T10:16:33Z',
            birthday: null,
            customer: 1,
            dealer: 0,
            phoneNumber: null,
            authorized: 1,
            id: '27ee6d40-157f-4d3d-9524-351be9747eb2',
            address: null
          },
          {
            fullName: null,
            appId: 'hagN8ldwaL6s',
            email: null,
            brand: null,
            nickName: null,
            grade: null,
            point: 0,
            companyName: null,
            dealerAddress: null,
            rank: null,
            registeredDate: '2023-02-17T08:42:31Z',
            verifiedDate: '2023-02-17T08:42:31Z',
            birthday: null,
            customer: 1,
            dealer: 0,
            phoneNumber: null,
            authorized: 0,
            id: '745e593c-4c6f-4f93-b9ec-da3c355feec2',
            address: null
          },
          {
            fullName: null,
            appId: 'vvQ92Yp6JlEI',
            email: null,
            brand: null,
            nickName: null,
            grade: null,
            point: 0,
            companyName: null,
            dealerAddress: null,
            rank: null,
            registeredDate: '2023-02-17T08:42:31Z',
            verifiedDate: '2023-02-17T08:42:31Z',
            birthday: null,
            customer: 1,
            dealer: 0,
            phoneNumber: null,
            authorized: 0,
            id: '590f5b79-39fa-4775-ae84-7b225cd3158b',
            address: null
          }
        ],
        page: 0,
        pageSize: 5,
        totalRow: 237,
        totalPage: 48
      })
    );
  })
];
