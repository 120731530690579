import restServiceHelper from 'src/utils/restServiceHelper';
import restService from './restService';

class OptionService {
  async getCarTypes() {
    const url = `common/car-type`;
    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  async getFuelTypes() {
    const url = `common/fuel-type`;
    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  async getManufacturers(keyword = '') {
    const url = `administrator/manufacturer?page=${0}&keyword=${keyword}&size=${100}`;

    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  async getTrim(page = 0, keyword = '', size = 5) {
    const url = `administrator/car-trim?page=${page}&keyword=${keyword}&size=${size}`;
    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  async getFuelEfficiencies() {
    const url = 'common/fuel-efficiency';

    return restServiceHelper.requestAsync(restService.getAsync(url));
  }
}

const optionService = new OptionService();

export default optionService;
