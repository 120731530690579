import { rest } from 'msw';

export default [
  rest.get('http://localhost:9000/', (req, res, ctx) => {
    const { username } = req.body;
    return res(
      ctx.json({
        id: 'f79e82e8-c34a-4dc7-a49e-9fadc0979fda',
        username,
        firstName: 'John',
        lastName: 'Maverick'
      })
    );
  }),
  rest.get(
    'http://localhost:9000/api/administrator/car-model?page=0&keyWord=&size=5',
    async (req, res, ctx) => {
      return res(
        ctx.json({
          items: [
            {
              carType: null,
              brandId: 1,
              color: null,
              numOfTrim: 0,
              brandName: 'Benz',
              fuelType: null,
              name: 'test 23',
              id: '884eeabe-6756-4c89-9837-bbc202ffba53'
            },
            {
              carType: 'coupe',
              brandId: 3,
              color: null,
              numOfTrim: 1,
              brandName: 'Audi',
              fuelType: 'Gasoline',
              name: 'R8',
              id: '84c0a5c1-6575-11ed-9577-8cec4b476903'
            },
            {
              carType: 'SUV',
              brandId: 3,
              color: null,
              numOfTrim: 1,
              brandName: 'Audi',
              fuelType: 'Gasoline',
              name: 'RS Q8',
              id: '84c000d7-6575-11ed-9577-8cec4b476903'
            },
            {
              carType: 'sedan',
              brandId: 3,
              color: null,
              numOfTrim: 2,
              brandName: 'Audi',
              fuelType: 'EV',
              name: 'RS E-tron',
              id: '84bf703f-6575-11ed-9577-8cec4b476903'
            },
            {
              carType: 'sedan',
              brandId: 3,
              color: null,
              numOfTrim: 1,
              brandName: 'Audi',
              fuelType: 'Gasoline',
              name: 'RS7',
              id: '84bf04f0-6575-11ed-9577-8cec4b476903'
            }
          ],
          page: 0,
          pageSize: 5,
          totalRow: 106,
          totalPage: 22
        })
      );
    }
  )
];
