import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import BrandAutocomplete from 'src/components/BrandAutocomplete';
import TrimAutocomplete from 'src/components/TrimAutocomplete';

const linkToType = {
  WEBSITE: 1,
  BRAND: 2,
  TRIM: 3
};

const linkToTypeText = {
  [linkToType.WEBSITE]: 'Website link',
  [linkToType.BRAND]: 'Brand link',
  [linkToType.TRIM]: 'Trim link'
};

const getLinkToType = (url = '') => {
  if (!url) {
    return linkToType.WEBSITE;
  }

  if (url.startsWith('http://') || url.startsWith('https://')) {
    return linkToType.WEBSITE;
  }

  if (url.startsWith('car?carId=')) {
    return linkToType.TRIM;
  }

  if (url.startsWith('search?brand=')) {
    return linkToType.BRAND;
  }

  return linkToType.WEBSITE;
};
export default function SelectLink({ value, onChange, label, ...field }) {
  const [type, setType] = useState(linkToType.WEBSITE);

  const _handleChangeType = (e) => {
    onChange('');
    setType(e.target.value);
  };

  const _handleSelectBrand = (e, v) => onChange(`search?brand=${v.id}`);

  const _handleChangeWebsiteLink = (e) => onChange(e.target.value);

  const _handleSelectTrim = (e, v) => onChange(`car?carId=${v.id}`);

  useEffect(() => {
    const _type = getLinkToType(value);
    if (_type !== type) {
      setType(_type);
    }
  }, []);
  return (
    <Box>
      <Stack>
        {label && <Typography variant="text" style={{ width: 150 }}>{`${label}`}</Typography>}

        <TextField
          {...field}
          size="small"
          select
          value={type}
          onChange={_handleChangeType}
          style={{ marginBottom: '16px' }}
          label={!label ? 'Link type' || '' : ''}
          fullWidth
        >
          {Object.values(linkToType).map((v) => (
            <MenuItem key={v} value={v}>
              {linkToTypeText[v]}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <Box marginTop="16px">
        {type === linkToType.WEBSITE && (
          <Stack>
            <Typography variant="text" style={{ width: 150 }}>
              URL Link
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={value}
              label=""
              onChange={_handleChangeWebsiteLink}
            />
          </Stack>
        )}
        {type === linkToType.BRAND && (
          <Stack>
            <Typography variant="text" style={{ width: 150 }}>
              Brand Link
            </Typography>
            <BrandAutocomplete
              brandId={value ? value.split('=').pop() : null}
              onChange={_handleSelectBrand}
              label=""
            />
          </Stack>
        )}
        {type === linkToType.TRIM && (
          <Stack>
            <Typography variant="text" style={{ width: 150 }}>
              Trim Link
            </Typography>
            <TrimAutocomplete
              trimId={value ? value.split('=').pop() : null}
              onChange={_handleSelectTrim}
              label=""
            />
          </Stack>
        )}
      </Box>
    </Box>
  );
}
