import { Avatar, IconButton } from '@mui/material';
import Iconify from 'src/components/Iconify';

export default function Image({ style, onRemove, removeable = true, ...props }) {
  return (
    <div style={{ ...style, position: 'relative', width: '100px', height: '100px' }}>
      <Avatar {...props} style={{ width: '100px', height: '100px', borderRadius: '8px' }} />
      {removeable && (
        <IconButton
          style={{ position: 'absolute', top: '0px', right: '0px', backgroundColor: 'white' }}
          color="error"
          onClick={onRemove}
          size="small"
        >
          <Iconify icon="ic:baseline-clear" />
        </IconButton>
      )}
    </div>
  );
}
