import imageService from 'src/services/imageService';
import privateStore from 'src/stores/PrivateStore';

const convertToImageUrl = (data) =>
  `${data.serverURL}/${data.images[0]}?token=${privateStore.getToken()}`;

export default class CKUploadAdapter {
  constructor(loader, handleUploadImageSuccessRef) {
    this.loader = loader;
    this.handleUploadImageSuccessRef = handleUploadImageSuccessRef;
    this.cancel = false;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          imageService
            .upload(file)
            .then((res) => {
              if (res.error) {
                reject(res.error);
              } else if (this.cancel) {
                imageService.delete(res.data.images);
                reject(new Error('cancel'));
              } else {
                resolve({ default: convertToImageUrl(res.data) });
                this.handleUploadImageSuccessRef.current(res.data);
              }
            })
            .catch((e) => reject(e));
        })
    );
  }

  abort() {
    this.cancel = true;
  }
}
