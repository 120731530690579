/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Stack,
  Avatar,
  Button,
  Container,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox
} from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import Table from 'src/components/Table';
import RightDrawer from 'src/components/RightDrawer';
import Page from 'src/components/Page';
import Iconify from 'src/components/Iconify';
import LoadImage from 'src/components/LoadImage';
import { promotionType } from 'src/constants';
import promotionService from 'src/services/promotion';
import { boolean } from 'src/utils/formatBoolean';
import PromotionForm from './components/PromotionForm';

export default function Promotion() {
  const [state, setState] = useState({
    page: 0,
    size: 5,
    items: [],
    rowCount: 0,
    type: promotionType.MainPromotion
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const confirm = useConfirm();
  const [item, setItem] = useState(undefined);

  const { page, size, items, rowCount, type } = state;

  const _getData = async () => {
    const result = await promotionService.listAsync(page, type, size);
    if (result.data) {
      setState({ ...state, items: result.data.items, rowCount: result.data.totalRow });
    }
  };

  useEffect(() => {
    _getData();
  }, [page, size, type]);

  const _updatePromote = async (e, row) => {
    const { value } = e.target;
    const formData = new FormData();
    formData.append('type', row.type);

    if (row.priority) {
      formData.append('priority', row.priority);
    }

    if (row.urlLink) {
      formData.append('urlLink', row.urlLink);
    }
    formData.append('isActive', !boolean(value));
    const result = await promotionService.updateAsync(row.id, formData);

    if (!result.error) {
      _getData();
      setItem(undefined);
    } else {
      const content =
        result.error?.data.message || 'An error has occurred. Please reload and try again.';
      confirm({
        title: <Typography fontWeight="bold">Error</Typography>,
        content,
        cancellationButtonProps: { style: { display: 'none' } }
      });
    }
  };

  const configs = [
    {
      key: 'image',
      name: 'Image',
      render: (row) => (
        <LoadImage
          Component={Avatar}
          alt={row.name}
          src={row.image}
          imgProps={{ style: { objectFit: 'contain' } }}
          variant="square"
        />
      )
    },
    { key: 'urlLink', name: 'Link to' },
    { key: 'priority', name: 'Priority' },
    { key: 'userType', name: 'User Type' },
    {
      key: 'isActive',
      name: 'Active',
      render: (row) => (
        <Checkbox
          value={row.isActive}
          checked={row.isActive}
          onClick={(e) => _updatePromote(e, row)}
        />
      )
    }
  ];

  const _handleChangePage = (page) => {
    setState({ ...state, page });
  };

  const _handleChangeRowsPerPage = (rowsPerPage) => {
    setState({ ...state, size: rowsPerPage, page: 0 });
  };

  const _handleAddUser = () => {
    setItem(null);
  };

  const _handleEdit = (id) => {
    const car = state.items.find((x) => x.id === id);
    const clonedCar = JSON.parse(JSON.stringify(car));

    setItem(clonedCar);
  };

  const _handleCancel = (e, reason) => {
    if (((reason === 'escapeKeyDown' || reason === 'backdropClick') && !isSubmitting) || !reason) {
      setItem(undefined);
    }
  };

  const _submit = async (data) => {
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('type', data.type);

    formData.append('isActive', data.isActive);

    if (data.priority) {
      formData.append('priority', data.priority);
    }

    if (data.urlLink) {
      formData.append('urlLink', data.urlLink);
    }

    if (item === null) {
      formData.append('promotionImage', data.promotionImage);

      const result = await promotionService.createAsync(formData);

      if (!result.error) {
        _getData();
        setItem(undefined);
      }
    } else {
      if (data.updateImage) {
        formData.append('updateImage', data.updateImage);
      }

      const result = await promotionService.updateAsync(data.id, formData);

      if (!result.error) {
        _getData();
        setItem(undefined);
      }
    }

    setIsSubmitting(false);
  };

  const _handleDelete = async (id) => {
    const result = await promotionService.deleteAsync(id);

    if (!result.error) {
      _getData();
    }

    let content = 'An error has occured. Please reload and try again.';

    if (result.error?.data) {
      content = result.error.data.message;
    }

    confirm({
      title: <Typography fontWeight="bold">Error</Typography>,
      content,
      cancellationButtonProps: { style: { display: 'none' } }
    });
  };

  return (
    <Page title="Banner | Jessicar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Banner
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={_handleAddUser}
          >
            New banner
          </Button>
        </Stack>

        <Table
          searchPlaceholder="Search banner..."
          data={items}
          configs={configs}
          rowCount={rowCount}
          page={page}
          rowsPerPage={size}
          onChangePage={_handleChangePage}
          onChangeRowsPerPage={_handleChangeRowsPerPage}
          onEditRow={_handleEdit}
          onDeleteRow={_handleDelete}
          hideFilter
          FilterComponent={
            <FormControl style={{ width: '200px' }}>
              <InputLabel>Banner type</InputLabel>
              <Select
                value={type}
                label="Banner type"
                onChange={(e) => setState({ ...state, type: e.target.value })}
              >
                <MenuItem value={promotionType.MainPromotion}>Main banner</MenuItem>
                <MenuItem value={promotionType.SubPromotion}>Sub banner</MenuItem>
              </Select>
            </FormControl>
          }
        />

        <RightDrawer open={item !== undefined} onClose={_handleCancel}>
          <PromotionForm
            initialValues={item}
            cancelButtonProps={{ disabled: isSubmitting }}
            submitButtonProps={{ loading: isSubmitting }}
            onCancel={_handleCancel}
            onSubmit={_submit}
          />
        </RightDrawer>
      </Container>
    </Page>
  );
}
