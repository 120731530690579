/* eslint-disable react/jsx-no-duplicate-props */
import { Dialog, InputAdornment, Slide, Stack, TextField, Typography } from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import Iconify from 'src/components/Iconify';
import SelectUserArea from './SelectUserArea';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function SelectGroupUser({
  field,
  errorField,
  fieldProps,
  label,
  onChangeMultipleSelectTable,
  initialValues
}) {
  const [isOpenSelectUser, setIsOpenSelectUser] = useState(false);
  const [listUserSelect, setListUserSelect] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const _handleCancel = () => {
    setIsOpenSelectUser(false);
  };

  const _selectUsers = () => {
    setIsOpenSelectUser(true);
  };

  const _handleSubmit = (value) => {
    setListUserSelect(value);
    onChangeMultipleSelectTable(value, 'users');
    setIsOpenSelectUser(false);
  };
  useEffect(() => {
    setListUserSelect(initialValues);
  }, [initialValues]);

  return (
    <Stack style={{ marginBottom: '16px' }}>
      {label && <Typography variant="text" style={{ width: 150 }}>{`${label}`}</Typography>}
      <TextField
        {...field}
        size="small"
        fullWidth
        style={{ marginBottom: '16px' }}
        error={Boolean(errorField)}
        {...fieldProps}
        onClick={_selectUsers}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="start">
              <Iconify icon="mdi:user-group" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          )
        }}
        inputProps={{
          value:
            listUserSelect.length < 4
              ? listUserSelect.map((user) => user.appId)
              : `${listUserSelect.slice(0, 3).map((user) => user.appId)}, and ${
                  listUserSelect.length - 3
                } more`
        }}
        label={!label ? fieldProps.label || '' : ''}
        type="text"
      />

      <Dialog
        fullWidth
        maxWidth="xl"
        open={isOpenSelectUser}
        TransitionComponent={Transition}
        onClose={_handleCancel}
      >
        <SelectUserArea
          selects={listUserSelect}
          cancelButtonProps={{ disabled: isSubmitting }}
          submitButtonProps={{ loading: isSubmitting }}
          onCancel={_handleCancel}
          onSubmit={_handleSubmit}
        />
      </Dialog>
    </Stack>
  );
}
