import { Avatar, Box, Container, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import FormLayout from 'src/components/FormLayout';
import LoadImage from 'src/components/LoadImage';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'src/components/Select';
import palette from 'src/theme/palette';
import { useSearchParams } from 'react-router-dom';
import userService from 'src/services/user';
import accountBrandService from 'src/services/accountBrand';
import { LoadingButton } from '@mui/lab';
import makeForm from 'src/utils/makeForm';
import { useAccountBrandEdit } from 'src/stores/accountBrandEdit';
import _ from 'underscore';

const defaultValues = {
  id: '',
  status: '',
  businessCard: '',
  dealerAddress: '',
  companyName: '',
  // null, true
  subscriptionType: null,
  // '1 Month', '1 Year', null
  subscriptionStatus: null,
  subscription: null
  // type: '',
  // userStatus: ''
};

const getInitialValues = (initialValues) => {
  if (!initialValues) {
    return defaultValues;
  }

  return {
    ...initialValues,
    priority: initialValues.priority
  };
};

const validateSchema = yup
  .object()
  .shape({
    dealerAddress: yup.string().trim().required('Please enter dealer address.'),
    companyName: yup.string().trim().required('Please enter name of dealer company.')
  })
  .required();

// ChangeStatusForm.propTypes = {
//   initialValues: PropTypes.array,
//   title: PropTypes.string,
//   cancelButtonProps: PropTypes.object,
//   submitButtonProps: PropTypes.object,
//   onSubmit: PropTypes.func,
//   onCancel: PropTypes.func
// };

// ChangeStatusForm.defaultProps = {
//   initialValues: null,
//   title: undefined,
//   cancelButtonProps: {},
//   submitButtonProps: {},
//   onSubmit: () => null,
//   onCancel: () => null
// };

const listOption = [
  { id: 'New', name: 'New' },
  { id: 'Accepted', name: 'Accepted' },
  { id: 'Rejected', name: 'Rejected' }
];

const subscriptionTypeMap = {
  '1 Month': 'OneMonth',
  '3 Month': 'ThreeMonth',
  '1 Year': 'OneYear'
};

export default function ChangeStatusForm({
  // initialValues,
  // title,
  // cancelButtonProps,
  // submitButtonProps,
  // onSubmit,
  // onCancel
  onRefresh
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    // getValues,
    watch,
    reset
  } = useForm({
    defaultValues: getInitialValues(),
    resolver: yupResolver(validateSchema)
  });
  const businessCard = watch('businessCard');
  const subscriptionStatus = watch('subscriptionStatus');
  const subscriptionPeriod = watch('subscriptionPeriod');
  const status = watch('status');

  const [loading, setLoading] = useState(false);

  // render: (row) => (row.subscriptionStatus ? 'Subscribed' : 'Unsubscribed')
  // const [isRequired, setIsRequired] = useState(getValues('status') === 'Accepted');'
  const isRequired = status === 'Accepted';
  const [searchParams, setSearchParams] = useSearchParams();
  // 조회시에 사용하는 ID
  // 브랜드 정보 수정시에 사용하는 ID와 다름에 유의
  const accountBrandId = useMemo(() => searchParams.get('account-brand-id'), [searchParams]);
  const { accountBrand } = useAccountBrandEdit();
  const getUser = async (userId) => {
    const reqUser = await userService.getAsync(userId);
    if (reqUser.data) {
      const _data = {
        ...reqUser.data,
        ..._.pick(accountBrand, [
          'id',
          'status',
          'subscription',
          'subscriptionName',
          'subscriptionStatus',
          'subscriptionType',
          'subscriptionPeriod',
          'businessCard'
        ])
      };
      // if (_data.subscriptionType) {
      //   _data.subscriptionType = subscriptionTypeMap[_data.subscription];
      // }
      console.log(_data);
      reset(_data);
    }
  };

  useEffect(() => {
    if (accountBrandId) {
      getUser(accountBrandId);
    }
  }, [accountBrandId]);
  // <FormLayout
  //   title="Edit Business Card"
  //   width={500}
  //   cancelButtonProps={{ ...cancelButtonProps, onClick: onCancel }}
  //   submitButtonProps={{ ...submitButtonProps, onClick: handleSubmit(onSubmit) }}
  // >

  const onSubmit = async (data) => {
    setLoading(true);
    // setIsSubmitting(true);

    // const formData = new FormData();
    // formData.append('status', data.status);
    // if (data.status === 'Accepted') {
    //   formData.append('dealerAddress', data.dealerAddress);
    //   formData.append('companyName', data.companyName);
    // }

    // formData.append('type', data.subscriptionStatus);
    // formData.append('subscriptionPeriod', data.subscription);
    const _data = _.pick(data, [
      // subscriptionStatus는 제거
      'status',
      'subscription',
      'subscriptionName',
      'subscriptionPeriod',
      'subscriptionType'
    ]);
    if (data.subscriptionStatus === true) {
      _data.dealerAddress = data.dealerAddress;
      _data.companyName = data.companyName;
    }

    if (!data.subscriptionType) _data.subscriptionType = 'None';

    const formData = makeForm(_data);

    const result = await accountBrandService.updateAsync(data.id, formData);

    onRefresh();
    // if (!result.error) {
    //   _getData();
    // } else {
    //   const content =
    //     result.error.data?.message || 'An error has occurred. Please reload and try again.';

    //   confirm({
    //     title: <Typography fontWeight="bold">Error</Typography>,
    //     content,
    //     cancellationButtonProps: { style: { display: 'none' } }
    //   });
    // }
    // setItem(undefined);
    // setIsSubmitting(false);
    setLoading(false);
    setSearchParams({});
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        width: '100%',
        maxWidth: 500,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography
        variant="h4"
        sx={{
          padding: '10px 20px',
          borderBottom: `1px solid ${palette.grey[400]}`
        }}
      >
        Edit Business Card
      </Typography>
      <Container sx={{ paddingY: '16px' }}>
        <LoadImage
          Component={Avatar}
          sx={{ width: '100%', height: 200 }}
          alt=""
          src={businessCard}
          imgProps={{ objectFit: 'contain' }}
          variant="square"
        />

        <Box sx={{ height: '30px', display: 'block' }} className="margin" />
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              label="status"
              options={listOption}
              getValue={(opt) => opt.id}
              getLabel={(opt) => opt.name}
              onChange={(e) => {
                const { name, value } = e.target;
                if (value === 'Accepted') {
                  setValue('companyName', '');
                  setValue('dealerAddress', '');
                  // setIsRequired(true);
                } else {
                  setValue('companyName', 'null');
                  setValue('dealerAddress', 'null');
                  // setIsRequired(false);
                }
                setValue(name, value);
              }}
              style={{ marginBottom: '16px' }}
            />
          )}
        />
        {isRequired && (
          <>
            <Controller
              name="dealerAddress"
              control={control}
              render={({ field }) => (
                <Stack direction="row">
                  <Typography variant="text">Dealer Address</Typography>
                  <TextField
                    {...field}
                    size="small"
                    fullWidth
                    style={{ marginBottom: '16px' }}
                    error={Boolean(errors.dealerAddress)}
                    type="text"
                  />
                </Stack>
              )}
            />
            <Controller
              name="companyName"
              control={control}
              render={({ field }) => (
                <Stack direction="row">
                  <Typography variant="text">Company Name</Typography>
                  <TextField
                    {...field}
                    size="small"
                    fullWidth
                    style={{ marginBottom: '16px' }}
                    error={Boolean(errors.companyName)}
                    type="text"
                  />
                </Stack>
              )}
            />
            <Controller
              name="subscriptionStatus"
              control={control}
              render={({ field }) => (
                <Select
                  disabled
                  options={[
                    { label: 'subscribed', value: true },
                    { label: 'unsubscribed', value: null }
                  ]}
                  getValue={(v) => v.value}
                  getLabel={(v) => v.label}
                  sx={{ mb: '16px' }}
                  {...field}
                />
              )}
            />
            {subscriptionStatus !== null && (
              <Controller
                name="subscriptionType"
                control={control}
                render={({ field }) => (
                  <Select
                    label="subscription period"
                    options={[
                      { label: '1 Month', value: 'OneMonth' },
                      { label: '3 Month', value: 'ThreeMonth' },
                      { label: '1 Year', value: 'OneYear' }
                    ]}
                    getValue={(v) => v.value}
                    getLabel={(v) => v.label}
                    {...field}
                  />
                )}
              />
            )}
          </>
        )}
      </Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '20px',
          borderTop: `1px solid ${palette.grey[400]}`
        }}
      >
        <LoadingButton
          variant="outlined"
          color="inherit"
          sx={{ paddingX: '20px' }}
          onClick={() => setSearchParams({})}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          sx={{ paddingX: '20px', marginLeft: '20px' }}
          loading={loading}
        >
          Submit
        </LoadingButton>
      </Box>
    </Box>
  );
}
