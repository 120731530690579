import restServiceHelper from 'src/utils/restServiceHelper';
import restService from './restService';

class BrandService {
  async createAsync(data) {
    const url = `administrator/manufacturer`;

    return restServiceHelper.requestAsync(
      restService.postAsync(url, data, { 'Content-Type': 'multipart/form-data' })
    );
  }

  async updateAsync(id, data) {
    const url = `administrator/manufacturer/${id}`;

    return restServiceHelper.requestAsync(restService.putAsync(url, data));
  }

  async deleteAsync(id) {
    const url = `administrator/manufacturer/${id}/delete`;

    return restServiceHelper.requestAsync(restService.putAsync(url));
  }

  async listAsync(page = 0, keyword = '', size = 5) {
    const url = `administrator/manufacturer?page=${page}&keyword=${keyword}&size=${size}`;

    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  async listPriorityAsync() {
    const url = `administrator/manufacturer/priority`;

    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  async getAsync(id) {
    const url = `administrator/manufacturer/${id}`;

    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  async getModels(manufacturerId) {
    const url = `administrator/manufacturer/car-brand/${manufacturerId}`;
    return restServiceHelper.requestAsync(restService.getAsync(url));
  }
}

const brandService = new BrandService();

export default brandService;
