/* eslint-disable react-hooks/exhaustive-deps */
// material
import { Container } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormLayout from 'src/components/FormLayout';
import ControllerField from 'src/components/ControllerField';
import { useEffect, useState } from 'react';
import { GROUP_USER } from 'src/constants';
import notificationService from 'src/services/notification';
import SelectLink from '../SelectLink';

const defaultValues = {
  title: '',
  content: '',
  sendDate: null,
  users: '',
  groupSend: 'All',
  urlLink: ''
};

const getDefaultValues = (initialValues) => {
  if (!initialValues) {
    return defaultValues;
  }

  const values = {
    ...defaultValues,
    ...initialValues,
    sendDate: initialValues.sendDate ? new Date(initialValues.sendDate) : null
  };
  return values;
};

const validateSchema = yup
  .object()
  .shape({
    title: yup.string().max(100, 'Max length is 100.').required('Name is required.'),
    content: yup.string().required('Content is required.'),
    groupSend: yup.string().required('Group user is required.'),
    urlLink: yup.string().required('URL is required.')
  })
  .required();

export default function NotificationForm({
  initialValues,
  manufacturers,
  cancelButtonProps,
  submitButtonProps,
  formSchema,
  onSubmit,
  onCancel
}) {
  const {
    setValue,
    formState: { errors },
    handleSubmit,
    control,
    getValues
  } = useForm({
    defaultValues: getDefaultValues(initialValues),
    resolver: yupResolver(validateSchema)
  });

  const [controlShow, setControlShow] = useState({
    groupSend: getValues('groupSend'),
    linkType: getValues('linkType')
  });
  const [initialValueSelectUser, setInitialValueSelectUser] = useState([]);

  const { groupSend } = controlShow;
  const id = initialValues?.id;

  const _changeEditor = ({ data }) => {
    setValue('content', data.get());
  };

  const _onChangeSelect = (e) => {
    const { value, name } = e.target;
    console.log(name, value);
    setControlShow({ ...controlShow, [name]: value });
    setValue(name, value);
  };

  const _onChangeMultipleSelectTable = (value, name) => {
    const clonedValue = JSON.stringify(value);
    setValue(name, clonedValue);
  };

  const _getUser = async () => {
    const result = await notificationService.listUsersAsync(id);
    if (!result.error) {
      const { data } = result;
      setInitialValueSelectUser(data);
      const clonedValue = JSON.stringify(data);
      setValue('users', clonedValue);
    }
  };

  const _handleChangeLink = (value) => setValue('urlLink', value);
  const _onCloseDateTimePicker = (e) => {
    if (e.target.value) {
      setValue('sendDate', null);
    }
  };
  const _onChangeDateTimePicker = (e) => {
    setValue('sendDate', e);
  };

  useEffect(() => {
    if (groupSend === GROUP_USER[4] && id !== undefined) {
      _getUser();
      setControlShow({ ...controlShow, groupSend });
    }
  }, [groupSend]);

  return (
    <FormLayout
      title={initialValues !== null ? 'Edit notification' : 'New notification'}
      maxWidth="100%"
      cancelButtonProps={{ ...cancelButtonProps, onClick: onCancel }}
      submitButtonProps={{ ...submitButtonProps, onClick: handleSubmit(onSubmit) }}
    >
      <Container sx={{ paddingY: '16px' }}>
        {formSchema.map((schema, key) =>
          !schema.choose || schema.choose === controlShow[schema.controlShow] ? (
            <ControllerField
              key={key}
              schema={schema}
              control={control}
              errors={errors}
              changeEditor={_changeEditor}
              onChangeSelectExtra={_onChangeSelect}
              onChangeMultipleSelectTable={_onChangeMultipleSelectTable}
              initialValueSelectUser={initialValueSelectUser}
              onCloseDateTimePicker={_onCloseDateTimePicker}
              onChangeDateTimePicker={_onChangeDateTimePicker}
            />
          ) : null
        )}
        <Controller
          name="urlLink"
          control={control}
          render={({ field }) => (
            <SelectLink
              value={field.value}
              onChange={_handleChangeLink}
              label="Link Type"
              {...field}
            />
          )}
        />
      </Container>
    </FormLayout>
  );
}
