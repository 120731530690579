import { yupResolver } from '@hookform/resolvers/yup';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
// material
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
// component
import { cognitoClientId, userPoolId } from 'src/constants';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string().required('Password is required')
});

export default function LoginForm({ onSuccess }) {
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(LoginSchema)
  });

  const _handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const _submit = async (data) => {
    console.log('log-in data submitting');
    setIsSubmitting(true);
    try {
      const authenticationDetails = new AuthenticationDetails({
        Username: data.email,
        Password: data.password
      });

      const userPool = new CognitoUserPool({
        UserPoolId: userPoolId,
        ClientId: cognitoClientId
      });

      const userData = {
        Username: data.email,
        Pool: userPool
      };

      const cognitoUser = new CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess(result) {
          setIsSubmitting(false);
          onSuccess(result);
        },
        onFailure(err) {
          alert(err.message || JSON.stringify(err));
          setIsSubmitting(false);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(_submit)}>
      <Stack spacing={3}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              error={Boolean(errors.email)}
              helperText={errors.email}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={_handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(errors.password)}
              helperText={errors.password}
            />
          )}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{ marginTop: '36px' }}
      >
        Login
      </LoadingButton>
    </form>
  );
}
