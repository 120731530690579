import { useEffect, useState } from 'react';
import { Container, Stack, TextField, Typography } from '@mui/material';
import Page from 'src/components/Page';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import settingService from 'src/services/setting';
import { LoadingButton } from '@mui/lab';

const defaultValues = {
  calculationRange: '',
  chatWeight: '',
  contractWeight: ''
};

const validationSchema = yup
  .object()
  .shape({
    calculationRange: yup
      .number()
      .typeError('Rate is required.')
      .moreThan(0, 'Priority must be more than 0.')
      .required('Rate is required.'),
    chatWeight: yup
      .number()
      .typeError('Rate is required.')
      .moreThan(0, 'Priority must be more than 0.')
      .required('Rate is required.'),
    contractWeight: yup
      .number()
      .typeError('Priority is required.')
      .moreThan(0, 'Priority must be more than 0.')
      .required('Priority is required.')
  })
  .required();

export default function Setting() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  });

  const _getData = async () => {
    // hard code id = 1
    const result = await settingService.getAsync(1);

    if (result.data) {
      reset(result.data);
    }
  };

  useEffect(() => {
    _getData();
  }, []);

  const submit = async (data) => {
    setIsSubmitting(true);

    // hard code id = 1
    await settingService.updateAsync(1, data);

    setIsSubmitting(false);
  };

  return (
    <Page title="Rank | Jessicar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Setting
          </Typography>
        </Stack>

        <Stack sx={{ width: { xs: '100%', sm: '500px' } }} alignItems="flex-end">
          <Controller
            name="calculationRange"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label="Calculation range"
                fullWidth
                error={Boolean(errors.calculationRange)}
                required
                helperText={Boolean(errors.calculationRange) && errors.calculationRange.message}
              />
            )}
          />

          <Controller
            name="chatWeight"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label="Chat weight"
                fullWidth
                style={{ marginTop: '16px' }}
                error={Boolean(errors.chatWeight)}
                required
                helperText={Boolean(errors.chatWeight) && errors.chatWeight.message}
              />
            )}
          />

          <Controller
            name="contractWeight"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label="Contract weight"
                fullWidth
                error={Boolean(errors.contractWeight)}
                required
                style={{ marginTop: '16px' }}
                helperText={Boolean(errors.contractWeight) && errors.contractWeight.message}
              />
            )}
          />

          <div>
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{ marginTop: '16px' }}
              loading={isSubmitting}
              onClick={handleSubmit(submit)}
            >
              Save
            </LoadingButton>
          </div>
        </Stack>
      </Container>
    </Page>
  );
}
