export function downloadFile(axiosResponse, filename = 'untitled.txt') {
  // create file link in browser's memory
  const href = URL.createObjectURL(axiosResponse.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', filename); // or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}
