import { Avatar, Button, Container, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/Iconify';
import LoadImage from 'src/components/LoadImage';
import Page from 'src/components/Page';
import RightDrawer from 'src/components/RightDrawer';
import Table from 'src/components/Table';
import rankService from 'src/services/rank';
import RankForm from './components/RankForm';

const configs = [
  { key: 'name', name: 'Name' },
  {
    key: 'carName',
    name: 'Car',
    render: (row) => (
      <Stack direction="row" alignItems="center" spacing={2}>
        <LoadImage
          Component={Avatar}
          alt={row.name}
          src={JSON.parse(row.collections)[0]}
          imgProps={{ style: { objectFit: 'contain' } }}
        />
        <Typography variant="subtitle2" noWrap>
          {row.carName}
        </Typography>
      </Stack>
    )
  },
  { key: 'rate', name: 'Rate' },
  { key: 'priority', name: 'Priority' }
];

export default function CarRank() {
  const [state, setState] = useState({
    page: 0,
    size: 5,
    items: [],
    rowCount: 0
  });
  const [filter, setFilter] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [item, setItem] = useState(undefined);

  const { page, size, items, rowCount } = state;

  const _getData = async () => {
    const result = await rankService.listAsync(page, filter, size);
    if (result.data) {
      setState({ ...state, items: result.data.items, rowCount: result.data.totalRow });
    }
  };

  useEffect(() => {
    _getData();
  }, [page, size, filter]);

  const _handleChangePage = (page) => {
    setState({ ...state, page });
  };

  const _handleChangeRowsPerPage = (rowsPerPage) => {
    setState({ ...state, size: rowsPerPage, page: 0 });
  };

  const _handleAddUser = () => {
    setItem(null);
  };

  const _handleEdit = (id) => {
    const car = state.items.find((x) => x.id === id);
    const clonedCar = JSON.parse(JSON.stringify(car));

    setItem(clonedCar);
  };

  const _handleCancel = (e, reason) => {
    if (((reason === 'escapeKeyDown' || reason === 'backdropClick') && !isSubmitting) || !reason) {
      setItem(undefined);
    }
  };

  const submit = async (data) => {
    setIsSubmitting(true);
    const _data = { ...data, carTrimId: data.carId.id };

    if (item === null) {
      const result = await rankService.createAsync(_data);

      if (!result.error) {
        _getData();
        setItem(undefined);
      }
    } else {
      const result = await rankService.updateAsync(data.id, _data);

      if (!result.error) {
        _getData();
        setItem(undefined);
      }
    }

    setIsSubmitting(false);
  };

  const _handleDelete = async (id) => {
    const result = await rankService.deleteAsync(id);
    if (!result.error) {
      _getData();
    }
  };

  return (
    <Page title="Rank | Jessicar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Rank
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={_handleAddUser}
          >
            New rank
          </Button>
        </Stack>

        <Table
          searchPlaceholder="Search rank..."
          data={items}
          configs={configs}
          rowCount={rowCount}
          page={page}
          rowsPerPage={size}
          searchKey={filter}
          onChangePage={_handleChangePage}
          onChangeRowsPerPage={_handleChangeRowsPerPage}
          onChangeSearchKey={setFilter}
          onEditRow={_handleEdit}
          onDeleteRow={_handleDelete}
        />

        <RightDrawer open={item !== undefined} onClose={_handleCancel}>
          <RankForm
            initialValues={item}
            cancelButtonProps={{ disabled: isSubmitting }}
            submitButtonProps={{ loading: isSubmitting }}
            onCancel={_handleCancel}
            onSubmit={submit}
          />
        </RightDrawer>
      </Container>
    </Page>
  );
}
