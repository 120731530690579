import { carMode, carOption } from 'src/constants';
import restServiceHelper from 'src/utils/restServiceHelper';
import restService from './restService';

class CarService {
  async createAsync(formType = carMode.CAR_MODEL, data = {}) {
    const url = `administrator/${formType}`;

    return restServiceHelper.requestAsync(
      restService.postAsync(url, data, { 'Content-Type': 'multipart/form-data' })
    );
  }

  async updateAsync(id, formType = carMode.CAR_MODEL, data = {}) {
    const url = `administrator/${formType}/${id}/update`;

    return restServiceHelper.requestAsync(restService.putAsync(url, data));
  }

  async deleteAsync(type = carMode.CAR_MODEL, id = null) {
    const url = `administrator/${type}/${id}/delete`;

    return restServiceHelper.requestAsync(restService.deleteAsync(url));
  }

  async listAsync(type = carMode.CAR_MODEL, page = 0, keyword = '', size = 5) {
    const url = `administrator/${type}?page=${page}&keyWord=${keyword}&size=${size}`;

    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  async getBrandByAsync(type = carMode.CAR_MODEL, id = null) {
    const url = `administrator/manufacturer/${type}/${id}`;

    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  async listOptionsAsync(option = carOption.brand) {
    const url = `administrator/${option}/all`;

    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  async getTrimAsync(trimId) {
    const url = `administrator/car-trim/${trimId}`;
    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  async downloadExcelCarTrim(keyWord) {
    const url = `administrator/car-trim/excel/download`;
    return restServiceHelper.requestAsync(
      restService.getAsync(url, { keyWord }, { responseType: 'blob' })
    );
  }

  async uploadExcelCarTrim(formData) {
    const url = 'administrator/car-trim/excel/upload';
    return restServiceHelper.requestAsync(
      restService.postAsync(url, formData, { 'Content-Type': 'multipart/form-data' })
    );
  }

  async downloadExcelCarModel(keyWord) {
    const url = `administrator/car-model/excel/download`;
    return restServiceHelper.requestAsync(
      restService.getAsync(url, { keyWord }, { responseType: 'blob' })
    );
  }

  async uploadExcelCarModel(formData) {
    const url = 'administrator/car-model/excel/upload';
    return restServiceHelper.requestAsync(
      restService.postAsync(url, formData, { 'Content-Type': 'multipart/form-data' })
    );
  }

  /* 차량 정보 가져오기 추가 +*/
  async getCarDetail(modelId) {
    const url = `administrator/car-model/all`;
    const result = await restServiceHelper.requestAsync(restService.getAsync(url));

    // 데이터 중에서 modelId와 일치하는 데이터 찾기
    const matchingModel = result.data.find((item) => item.id === modelId);

    // 모델 아이디와 일치하는 데이터가 있다면 해당 데이터 반환, 아니면 null 반환
    return matchingModel || null;
  }

  async getCarDetail2(modelId, type = carMode.CAR_MODEL, page = 0, keyword = '', size = 5) {
    const url = `administrator/${type}?page=${page}&keyWord=${keyword}&size=${size}`;
    const result = await restServiceHelper.requestAsync(restService.getAsync(url));

    console.log('test-getCarDetail2', result);
    console.log('test-getCarDetail2-0', url);
    console.log('test-getCarDetail2-1', result.data);
    console.log('test-getCarDetail2-2', result.data.items);

    // 데이터 중에서 modelId와 일치하는 데이터 찾기
    const matchingModel = result.data.items
      ? result.data.items.find((item) => item.id === modelId)
      : null;
    console.log('test-getCarDetail2-3', matchingModel);

    // 모델 아이디와 일치하는 데이터가 있다면 해당 데이터 반환, 아니면 null 반환
    return matchingModel || null;
  }
}
const carService = new CarService();

export default carService;
