import restServiceHelper from 'src/utils/restServiceHelper';
import restService from './restService';

class AccountBrandService {
  async listAsync(keyword = '', status = '', brandId = '', size = 5, page = 0) {
    const url = `administrator/account-brand?page=${page}&keyword=${keyword}&size=${size}&status=${status}&brandId=${brandId}`;

    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  async getAsync(id) {
    const url = `administrator/account-brand/${id}/account`;
    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  async updateAsync(id, data) {
    const url = `administrator/account-brand/${id}`;
    return restServiceHelper.requestAsync(restService.putAsync(url, data));
  }
}

const accountBrandService = new AccountBrandService();

export default accountBrandService;
