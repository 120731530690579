// material
import LoadVideo from 'src/components/LoadVideo';

export default function Video({ src, onRemove }) {
  const _handleRemove = (e) => onRemove(src);
  if (!src) {
    return null;
  }

  if (typeof src === 'object') {
    return (
      <LoadVideo
        file={src}
        onRemove={_handleRemove}
        style={{ margin: '10px' }}
        removeable={Boolean(onRemove)}
      />
    );
  }

  return (
    <LoadVideo
      src={src}
      onRemove={_handleRemove}
      style={{ margin: '10px' }}
      removeable={Boolean(onRemove)}
    />
  );
}
