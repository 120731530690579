/* eslint-disable react/jsx-no-duplicate-props */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Checkbox,
  Container,
  Dialog,
  FormControlLabel,
  Grid,
  Slide,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import _ from 'underscore';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import FormLayout from 'src/components/FormLayout';
import { businessCardStatus, fieldType, subscriptionStatus } from 'src/constants';
import Table from 'src/components/Table';
import LoadImage from 'src/components/LoadImage';
import { Link } from 'react-router-dom';
import accountBrandService from 'src/services/accountBrand';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const defaultValues = {};

const configs = (viewDetails, appId) => [
  { key: 'brand', name: 'Brand Name' },
  {
    key: 'businessCard',
    name: 'Business Card',
    render: (row) => (
      <LoadImage
        Component={Avatar}
        alt={row.name}
        src={row.businessCard}
        imgProps={{ style: { objectFit: 'contain' } }}
        variant="square"
      />
    )
  },
  { key: 'status', name: 'Status' },
  {
    key: 'subscriptionStatus',
    name: 'Subscription Mode',
    render: (row) => (row.subscriptionStatus ? 'Subscribed' : 'Unsubscribed')
  },
  { key: 'subscription', name: 'Subscription Period' },
  { key: 'startDate', name: 'Start Date' },
  { key: 'endDate', name: 'End Date' },
  {
    render: ({ brandId }) => (
      <Tooltip title="View details">
        <Link
          to="/dashboard/account-brand"
          state={{ appId, brdId: brandId }}
          style={{ textDecoration: 'none' }}
        >
          View Brand
        </Link>
      </Tooltip>
    )
  }
];

const getDefaultValues = (initialValues) => {
  if (!initialValues) {
    return defaultValues;
  }

  const values = {
    ...defaultValues,
    ...initialValues,
    authorized: initialValues.authorized ? 'Authorized' : 'Unauthorized'
  };

  return values;
};

const getValidationSchema = yup
  .object()
  .shape({
    appId: yup.string().required('')
  })
  .required();

export default function UserForm({
  title,
  formSchema,
  initialValues,
  cancelButtonProps,
  submitButtonProps,
  onSubmit,
  onCancel,
  hideListBrand
}) {
  const {
    setValue,
    formState: { errors },
    handleSubmit,
    control,
    getValues
  } = useForm({
    defaultValues: getDefaultValues(initialValues),
    resolver: yupResolver(getValidationSchema)
  });

  const [accountBrandItem, setAccountBrandItem] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [listAccountBrand, setListAccountBrand] = useState([]);

  const _handleView = ({ id }) => {
    setAccountBrandItem(id);
  };

  const _handleCancel = (_e, reason) => {
    if (((reason === 'escapeKeyDown' || reason === 'backdropClick') && !isSubmitting) || !reason) {
      setAccountBrandItem(undefined);
    }
  };

  const _getListAccountBrand = async () => {
    const result = await accountBrandService.getAsync(initialValues.id);
    if (!result.error) {
      setListAccountBrand(result.data.data);
    }
  };

  useEffect(() => {
    _getListAccountBrand();
  }, []);

  return (
    <FormLayout
      title={title}
      cancelButtonProps={{ ...cancelButtonProps, onClick: onCancel }}
      submitButtonProps={
        submitButtonProps && { ...submitButtonProps, onClick: handleSubmit(onSubmit) }
      }
      maxWidth="100%"
    >
      <Container sx={{ paddingY: '16px' }}>
        <Grid container justifyContent="space-between">
          {formSchema.map((schema, key) => {
            const inputType = schema.type;

            switch (inputType) {
              case fieldType.TEXT:
                return (
                  <Controller
                    key={key}
                    name={schema.name}
                    control={control}
                    render={({ field }) => (
                      <Stack direction="row" style={{ width: '45%', minWidth: 350 }}>
                        <Typography
                          variant="text"
                          style={{ width: 150 }}
                        >{`${schema.label}`}</Typography>
                        <TextField
                          {...field}
                          size="small"
                          fullWidth
                          style={{ marginBottom: '16px' }}
                          error={Boolean(errors[schema.errorField])}
                          {...schema.fieldProps}
                          type="text"
                        />
                      </Stack>
                    )}
                  />
                );
              case fieldType.READONLY_TEXT:
                return (
                  <Controller
                    key={key}
                    name={schema.name}
                    control={control}
                    render={({ field }) => (
                      <Stack direction="row" style={{ width: '45%', minWidth: 350 }}>
                        {schema.label && (
                          <Typography
                            variant="text"
                            style={{ width: 150 }}
                          >{`${schema.label}`}</Typography>
                        )}
                        <TextField
                          {...field}
                          size="small"
                          fullWidth
                          InputProps={{ readOnly: true }}
                          style={{ marginBottom: '16px' }}
                          error={Boolean(errors[schema.errorField])}
                          {...schema.fieldProps}
                          inputProps={{ value: schema.render ? schema.render(field) : field.value }}
                          label={!schema.label ? schema.fieldProps.label || '' : ''}
                          type="text"
                        />
                      </Stack>
                    )}
                  />
                );
              case fieldType.CONTROL_MULTIPLE_CHECKS:
                // eslint-disable-next-line no-case-declarations
                const listControl = _.filter(formSchema, (fs) => fs.controlBy === schema.key);
                return (
                  <Stack
                    direction="row"
                    alignContent="space-between"
                    style={{ width: '45%', minWidth: 350 }}
                  >
                    <Typography
                      variant="text"
                      style={{ width: 150 }}
                    >{`${schema.label}`}</Typography>
                    <Stack alignContent="space-between">
                      {listControl.map((ctr, key) => (
                        <Controller
                          key={key}
                          control={control}
                          name={ctr.name}
                          render={({ field }) => (
                            <FormControlLabel
                              label={ctr.label}
                              labelPlacement="start"
                              style={{
                                marginLeft: 0,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                              control={
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  onChange={() => 0}
                                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                  {...ctr.fieldProps}
                                />
                              }
                            />
                          )}
                        />
                      ))}
                    </Stack>
                  </Stack>
                );
              case fieldType.LABEL:
                return (
                  <Controller
                    key={key}
                    name={schema.name}
                    control={control}
                    render={() =>
                      initialValues === null ? null : (
                        <Stack>
                          <Typography variant="text">{`${schema.label}: ${getValues(
                            schema.name
                          )}`}</Typography>
                        </Stack>
                      )
                    }
                  />
                );
              default:
                return null;
            }
          })}
        </Grid>

        {!hideListBrand && (
          <Table
            hideFilter
            data={listAccountBrand}
            configs={configs(_handleView, getValues('appId'))}
            action={false}
            hidePagination
          />
        )}
      </Container>
    </FormLayout>
  );
}
