import { LoadingButton } from '@mui/lab';
import {
  Box,
  ButtonGroup,
  Container,
  Dialog,
  IconButton,
  Paper,
  Slide,
  Stack,
  Typography
} from '@mui/material';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import Label from 'src/components/Label';
import Page from 'src/components/Page';
import Table from 'src/components/Table';
import { bidStatus, bidType, contractPeriod, purchaseCondition, purchaseType } from 'src/constants';
import bidService from 'src/services/bid';
import { downloadFile } from 'src/utils';

const statusColor = {
  [bidStatus.REGISTER]: 'info',
  [bidStatus.DEALING]: 'warning',
  [bidStatus.COMPLETE]: 'success'
};

const configs = [
  { key: 'carName', name: 'Car' },
  { key: 'bidPrice', name: 'Bid price' },
  { key: 'bidType', name: 'Bid type', render: (row) => bidType[row.bidType] },
  {
    key: 'purchaseCondition',
    name: 'Purchase condition',
    render: (row) => purchaseCondition[row.purchaseCondition]
  },
  { key: 'purchaseType', name: 'Purchase type', render: (row) => purchaseType[row.purchaseType] },
  { key: 'accountName', name: 'User' },
  { key: 'dealerName', name: 'Dealer' },
  {
    key: 'status',
    name: 'Status',
    render: (row) => (
      <Label variant="ghost" color={statusColor[row.status]}>
        {row.status}
      </Label>
    )
  },
  { key: 'contractLicenseeNumber', name: 'Contract licensee number' },
  {
    key: 'contractPeriod',
    name: 'Contract period',
    render: (row) => contractPeriod[row.contractPeriod]
  },
  { key: 'contractDeposit', name: 'Contract deposit' },
  { key: 'contractEmail', name: 'Contract email' },
  { key: 'contractAddress', name: 'Contract address' },
  { key: 'contractTel', name: 'Contract tel' },
  { key: 'contractDoc', name: ' Contract doc' },
  {
    key: 'chatHistory',
    name: 'Chat History',
    render: (row) => (
      <Link to={{ search: `?chat=${row.id}` }}>
        <IconButton>
          <Iconify icon="material-symbols:format-list-bulleted" />
        </IconButton>
      </Link>
    )
  }
];

function ChatItem({ data }) {
  return (
    <Box
      data-conversation-id={data.conversationId}
      sx={{ borderRadius: '3px', bgcolor: 'black', mt: '8px', p: '8px', color: 'white' }}
    >
      {data.content ?? ''}
    </Box>
  );
}

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
function ChatInfo() {
  const [searchParams, setSearchParams] = useSearchParams();
  const chatId = searchParams.get('chat');
  const [chats, setChats] = useState([]);
  const getChats = async () => {
    setChats([]);
    if (!chatId) return;
    const reqChatHistory = await bidService.chatHistory(chatId);
    if (!reqChatHistory?.data) return;
    setChats(reqChatHistory?.data);
  };
  useEffect(() => {
    getChats();
  }, [chatId]);
  return (
    <Paper sx={{ p: '20px' }}>
      <Typography>비딩 채팅 내역</Typography>
      <Box maxHeight="500px" sx={{ overflowY: 'auto' }}>
        {chats.map((chat) => (
          <ChatItem key={chat.conversationId} data={chat} />
        ))}
        {chats.length === 0 && <Typography>채팅 내역이 없습니다.</Typography>}
      </Box>
    </Paper>
  );
}

function BidStatus() {
  const [statusCount, setStatusCount] = useState({
    loading: false,
    complet: -10,
    contracted: -10,
    dealing: -1,
    total: -1
  });
  useEffect(async () => {
    const reqStatusCount = await bidService.statusCount();
    setStatusCount({ ...statusCount, loading: true });
    if (reqStatusCount.data) {
      setStatusCount({ ...reqStatusCount.data, loading: false });
    }
  }, []);
  return (
    <Stack
      className="bid-status"
      direction="row"
      sx={{
        '& > div': {
          borderRadius: '8px',
          flex: 1,
          bgcolor: 'white',
          ml: '10px',
          p: '20px',
          ':first-child': {
            ml: '0px'
          }
        },
        '.title': {
          fontWeight: 'bold',
          fontSize: '20px'
        }
      }}
      mb="20px"
    >
      <Box>
        <Typography className="title">전체</Typography>
        <Typography>{statusCount.total}</Typography>
      </Box>
      <Box>
        <Typography className="title">상담</Typography>
        <Typography>{statusCount.dealing}</Typography>
      </Box>
      <Box>
        <Typography className="title">계약</Typography>
        <Typography>{statusCount.contracted}</Typography>
      </Box>
      <Box>
        <Typography className="title">완료</Typography>
        <Typography>{statusCount.complet}</Typography>
      </Box>
    </Stack>
  );
}

const useFiles = ({ refFileInput, filter }) => {
  const [uploading, setUploading] = useState(false);
  const onFileSelect = async (ev) => {
    const file = ev?.target?.files?.[0];
    if (!file) return;
    const formData = new FormData();
    formData.append('excel', file);
    setUploading(true);
    await bidService.uploadExcel(formData);
    setUploading(false);
  };
  const onExcelUpload = () => {
    const fileInput = refFileInput.current;
    if (!fileInput) return;
    fileInput.click();
  };
  const onExcelDownload = async () => {
    const reqFile = await bidService.downloadExcel(filter);
    downloadFile(reqFile, `bid-${filter}.xlsx`);
  };
  return { onExcelDownload, onExcelUpload, onFileSelect, uploading };
};

export default function Bid() {
  const [state, setState] = useState({
    page: 0,
    size: 5,
    items: [],
    rowCount: 0,
    filter: ''
  });

  const { page, size, items, rowCount, filter } = state;

  useEffect(() => {
    _getData();
  }, [page, size, filter]);

  const _getData = async () => {
    const result = await bidService.listAsync(page, filter, size);
    if (result.data) {
      setState({ ...state, items: result.data.items, rowCount: result.data.totalRow });
    }
  };

  const _handleChangePage = (page) => {
    setState({ ...state, page });
  };

  const _handleChangeRowsPerPage = (rowsPerPage) => {
    setState({ ...state, size: rowsPerPage, page: 0 });
  };

  const _handleChangeFilter = (value) => {
    setState({ ...state, filter: value, page: 0 });
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const refFileInput = useRef();
  const { uploading, onExcelUpload, onExcelDownload, onFileSelect } = useFiles({
    refFileInput,
    filter
  });

  return (
    <Page title="Bid | Jessicar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Bid
          </Typography>
        </Stack>
        <Box display="flex" justifyContent="flex-end" mb="24px">
          <ButtonGroup>
            {/* <LoadingButton onClick={onExcelUpload} loading={uploading} variant="outlined">
              엑셀 업로드
            </LoadingButton> */}
            <input
              type="file"
              style={{ display: 'none' }}
              ref={refFileInput}
              onChange={onFileSelect}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <LoadingButton onClick={onExcelDownload} variant="outlined">
              엑셀 다운로드
            </LoadingButton>
          </ButtonGroup>
        </Box>
        <BidStatus />

        <Table
          data={items}
          configs={configs}
          rowCount={rowCount}
          page={page}
          searchPlaceholder="Search bid..."
          rowsPerPage={size}
          searchKey={filter}
          onChangePage={_handleChangePage}
          onChangeRowsPerPage={_handleChangeRowsPerPage}
          onChangeSearchKey={_handleChangeFilter}
          action={false}
        />
        <Dialog
          fullWidth
          maxWidth="sm"
          open={searchParams.get('chat')}
          TransitionComponent={Transition}
          onClose={() => setSearchParams({})}
        >
          <ChatInfo />
        </Dialog>
      </Container>
    </Page>
  );
}
