import { useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Typography, Avatar, IconButton } from '@mui/material';
// components
import { AuthContext } from 'src/stores/AuthContext';
import MenuPopover from '../../components/MenuPopover';
//

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { logout, name, email, avatar } = useContext(AuthContext);

  const _handleOpen = () => {
    setOpen(true);
  };

  const _handleClose = () => {
    setOpen(false);
  };

  const _handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={_handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={avatar} alt="avatar" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={_handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={_handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
