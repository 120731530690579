// material
import {
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Box } from '@mui/system';
import { yupResolver } from '@hookform/resolvers/yup';
import FormLayout from 'src/components/FormLayout';
import LoadImage from 'src/components/LoadImage';
import Image from 'src/components/Image';
import FilePicker from 'src/components/FilePicker';
import LinkToSelection from '../LinkToSelection';

const defaultValues = {
  promotionImage: null,
  priority: null,
  urlLink: '',
  type: 'MainPromotion',
  userType: 'customer'
};

const getValidateSchema = (isNew) => {
  const schema = {
    urlLink: yup.string(),
    priority: yup.lazy((value) =>
      value === ''
        ? yup.string()
        : yup
            .number()
            .typeError('Minimum value of priority is 1.')
            .min(1, 'Minimum value of priority is 1.')
            .nullable()
    ),
    type: yup.string().required('Please select banner type.'),
    userType: yup.boolean().required()
  };

  if (isNew) {
    schema.promotionImage = yup.mixed().required('Please select banner image.');
  }

  return yup.object(schema).required();
};

const getInitialValues = (initialValues) => {
  if (!initialValues) {
    return defaultValues;
  }

  return { ...initialValues, updateImage: null };
};

export default function PromotionForm({
  initialValues,
  cancelButtonProps,
  submitButtonProps,
  onSubmit,
  onCancel
}) {
  const isNew = !initialValues;
  const {
    setValue,
    formState: { errors },
    handleSubmit,
    control,
    getValues
  } = useForm({
    defaultValues: getInitialValues(initialValues),
    resolver: yupResolver(getValidateSchema(isNew))
  });

  const _handleSelectImage = (e) => setValue('promotionImage', e.target.files[0]);

  const _handleRemoveImage = () => setValue('promotionImage', null);

  const _handleSelectUpdateImage = (e) => setValue('updateImage', e.target.files[0]);

  const _handleRemoveUpdateImage = () => setValue('updateImage', null);

  const _handleChangeLink = (value) => setValue('urlLink', value);

  const renderImage = (src) => {
    if (!src) {
      return null;
    }

    if (typeof src === 'object') {
      return (
        <LoadImage
          Component={Image}
          file={src}
          alt={src.name}
          onRemove={isNew ? _handleRemoveImage : _handleRemoveUpdateImage}
          style={{ margin: '10px' }}
        />
      );
    }

    return <LoadImage Component={Image} src={src} alt={src} style={{ margin: '10px' }} />;
  };

  return (
    <FormLayout
      title={initialValues !== null ? 'Edit banner' : 'New banner'}
      cancelButtonProps={{ ...cancelButtonProps, onClick: onCancel }}
      submitButtonProps={{ ...submitButtonProps, onClick: handleSubmit(onSubmit) }}
    >
      <Container sx={{ paddingY: '16px' }}>
        <FormControl required error={Boolean(errors.type)}>
          <FormLabel>Type</FormLabel>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel value="MainPromotion" control={<Radio />} label="Main banner" />
                <FormControlLabel value="SubPromotion" control={<Radio />} label="Sub banner" />
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl sx={{ display: 'block' }} required error={Boolean(errors.type)}>
          <FormLabel>User Type</FormLabel>
          <Controller
            name="userType"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel value="customer" control={<Radio />} label="Customer" />
                <FormControlLabel value="dealer" control={<Radio />} label="Dealer" />
              </RadioGroup>
            )}
          />
        </FormControl>
        {isNew && (
          <FilePicker
            label="Image"
            onChange={_handleSelectImage}
            error={Boolean(errors.promotionImage)}
            accept="image/*"
            required
          />
        )}
        {isNew && (
          <Controller
            name="promotionImage"
            control={control}
            render={({ field }) => renderImage(field.value)}
          />
        )}
        {!isNew && (
          <FilePicker label="Image" onChange={_handleSelectUpdateImage} accept="image/*" />
        )}
        {!isNew && (
          <Controller
            name="updateImage"
            control={control}
            render={({ field }) => renderImage(field.value || getValues('image'))}
          />
        )}
        <Controller
          name="priority"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Priority"
              fullWidth
              type="number"
              value={field.value ?? ''}
              error={Boolean(errors.priority)}
              helperText={errors.priority?.message || ''}
              style={{ marginTop: '16px' }}
            />
          )}
        />
        <Box marginTop="16px">
          <Controller
            name="urlLink"
            control={control}
            render={({ field }) => (
              <LinkToSelection value={field.value} onChange={_handleChangeLink} />
            )}
          />
        </Box>
      </Container>
    </FormLayout>
  );
}
