import PropTypes from 'prop-types';
// material
import {
  Card,
  Table as MuiTable,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import MoreMenu from 'src/components/MoreMenu';
import { useEffect, useState } from 'react';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import TableHead from './TableHead';
import TableToolbar from './TableToolbar';

Table.propTypes = {
  data: PropTypes.array.isRequired,
  configs: PropTypes.array.isRequired,
  orderBy: PropTypes.string,
  order: PropTypes.string,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowCount: PropTypes.number,
  selected: PropTypes.array,
  searchKey: PropTypes.string,
  onChangeOrder: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onChangeSearchKey: PropTypes.func,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteAll: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  checkable: PropTypes.bool,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  action: PropTypes.bool,
  handleChangeSelect: PropTypes.func,
  handleChangeDateRang: PropTypes.func,
  handleChangeDateTimeRang: PropTypes.func,
  moreFilterOptions: PropTypes.array,
  FilterComponent: PropTypes.any,
  hideFilter: PropTypes.bool,
  hidePagination: PropTypes.bool
};

Table.defaultProps = {
  orderBy: '',
  order: 'asc',
  page: 0,
  rowsPerPage: 5,
  rowCount: 0,
  selected: [],
  searchKey: '',
  onChangeOrder: () => null,
  onChangePage: () => null,
  onChangeRowsPerPage: () => null,
  onChangeSearchKey: () => null,
  onSelect: () => null,
  onSelectAll: () => null,
  onDeleteRow: () => null,
  onEditRow: () => null,
  onDeleteAll: () => null,
  editable: true,
  deletable: true,
  action: true,
  handleChangeSelect: () => null,
  handleChangeDateRang: () => null,
  handleChangeDateTimeRang: () => null,
  moreFilterOptions: [],
  FilterComponent: null,
  hideFilter: false,
  hidePagination: false
};

export default function Table({
  data,
  orderBy,
  order,
  page,
  rowsPerPage,
  rowCount,
  selected,
  searchKey,
  onChangeOrder,
  onChangePage,
  onChangeRowsPerPage,
  onChangeSearchKey,
  onSelect,
  onSelectAll,
  onDeleteRow,
  onEditRow,
  onDeleteAll,
  configs,
  searchPlaceholder,
  checkable,
  editable,
  deletable,
  action,
  handleChangeSelect,
  handleChangeDateRang,
  handleChangeDateTimeRang,
  moreFilterOptions,
  FilterComponent,
  hideFilter,
  hidePagination
}) {
  const [isSelectedAllThisPage, setIsSelectedAllThisPage] = useState(false);

  const _handleRequestSort = (event, property) => {
    const isAsc = orderBy !== property ? true : order !== 'asc';
    onChangeOrder(property, isAsc ? 'asc' : 'desc');
  };

  const _handleClick = (event, row) => {
    const isItemSelected = selected.findIndex((item) => item.id === row.id) !== -1;
    onSelect(row, isItemSelected);
  };

  const _handleChangePage = (event, newPage) => {
    onChangePage(newPage);
  };

  const _handleChangeRowsPerPage = (event) => {
    onChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  const _handleChangeSearchKey = (event) => {
    onChangeSearchKey(event.target.value);
  };

  const _handleChangeSelect = (value, property) => {
    handleChangeSelect(value, property);
  };

  const _handleChangeDateRang = (value, property) => {
    handleChangeDateRang(value, property);
  };

  const _handleChangeDateTimeRang = (value, property) => {
    handleChangeDateTimeRang(value, property);
  };

  const _onSelectAll = () => {
    onSelectAll(data, isSelectedAllThisPage);
  };

  const emptyRows = rowsPerPage - data.length;

  const isUserNotFound = data.length === 0;

  useEffect(() => {
    const check = data.every((v) => selected.findIndex((item) => item.id === v.id) !== -1);
    setIsSelectedAllThisPage(selected.length === 0 ? false : check);
  }, [selected, data]);

  return (
    <Card>
      <TableToolbar
        searchPlaceholder={searchPlaceholder}
        numSelected={selected.length}
        searchKey={searchKey}
        onChangeSearchKey={_handleChangeSearchKey}
        onDeleteAll={onDeleteAll}
        handleChangeSelect={_handleChangeSelect}
        handleChangeDateRang={_handleChangeDateRang}
        handleChangeDateTimeRang={_handleChangeDateTimeRang}
        FilterComponent={FilterComponent}
        hideFilter={hideFilter}
        moreFilterOptions={moreFilterOptions}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <MuiTable>
            <TableHead
              order={order}
              orderBy={orderBy}
              configs={configs}
              rowCount={data.length}
              onRequestSort={_handleRequestSort}
              onSelectAll={_onSelectAll}
              action={action}
              checkable={checkable}
              isSelectedAllThisPage={isSelectedAllThisPage}
            />

            <TableBody>
              {data.map((row) => {
                const isItemSelected = selected.findIndex((item) => item.id === row.id) !== -1;
                return (
                  <TableRow
                    hover
                    key={row.id}
                    tabIndex={-1}
                    role={checkable ? 'checkbox' : 'cell'}
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    {checkable && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) => _handleClick(event, row)}
                        />
                      </TableCell>
                    )}

                    {configs.map(({ key, render, cellProps }) => (
                      <TableCell align="left" {...cellProps} key={key}>
                        {render ? render(row) : row[key]}
                      </TableCell>
                    ))}

                    {action && (
                      <TableCell align="right" style={{ padding: '8px' }}>
                        <MoreMenu
                          onDelete={onDeleteRow}
                          onEdit={onEditRow}
                          id={row.id}
                          editable={editable}
                          deletable={deletable}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}

              {emptyRows > 0 && !isUserNotFound && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={searchKey} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </MuiTable>
        </TableContainer>
      </Scrollbar>
      {!hidePagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={_handleChangePage}
          onRowsPerPageChange={_handleChangeRowsPerPage}
          labelRowsPerPage=""
        />
      )}
    </Card>
  );
}
