import { fieldType } from '.';

export const userFormSchema = [
  {
    key: 'appId',
    name: 'appId',
    type: fieldType.READONLY_TEXT,
    label: 'App Id',
    errorField: 'appId',
    fieldProps: {
      type: 'text',
      size: 'small'
    }
  },
  {
    key: 'nickName',
    name: 'nickName',
    type: fieldType.TEXT,
    label: 'Nick Name',
    errorField: 'nickName',
    fieldProps: {
      type: 'text',
      size: 'small'
    }
  },
  {
    key: 'fullName',
    name: 'fullName',
    type: fieldType.READONLY_TEXT,
    label: 'Full Name',
    errorField: 'fullName',
    fieldProps: {
      type: 'text',
      size: 'small'
    }
  },
  {
    key: 'phoneNumber',
    name: 'phoneNumber',
    type: fieldType.READONLY_TEXT,
    label: 'Phone Number',
    errorField: 'phoneNumber',
    fieldProps: {
      type: 'text',
      size: 'small'
    }
  },
  {
    key: 'email',
    name: 'email',
    type: fieldType.READONLY_TEXT,
    label: 'Email',
    errorField: 'email',
    fieldProps: {
      type: 'text',
      size: 'small'
    }
  },
  {
    key: 'birthday',
    name: 'birthday',
    type: fieldType.READONLY_TEXT,
    label: 'Birthday',
    errorField: 'birthday',
    fieldProps: {
      type: 'text',
      size: 'small'
    }
  },
  {
    key: 'registeredDate',
    name: 'registeredDate',
    type: fieldType.READONLY_TEXT,
    label: 'Member Since',
    errorField: 'registeredDate',
    fieldProps: {
      type: 'text',
      size: 'small'
    }
  },
  {
    key: 'verifiedDate',
    name: 'verifiedDate',
    type: fieldType.READONLY_TEXT,
    label: 'Authorization Date',
    errorField: 'verifiedDate',
    fieldProps: {
      type: 'text',
      size: 'small'
    }
  },
  {
    key: 'authorized',
    name: 'authorized',
    type: fieldType.READONLY_TEXT,
    label: 'Status',
    errorField: 'authorized',
    fieldProps: {
      type: 'text',
      size: 'small'
    },
    render: ({ value }) => (value ? 'Authorized' : 'Unauthorized')
  },
  {
    key: 'userType',
    name: 'userType',
    type: fieldType.CONTROL_MULTIPLE_CHECKS,
    label: 'User Type'
  },
  {
    key: 'customer',
    name: 'customer',
    label: 'Customer',
    controlBy: 'userType',
    fieldProps: { disabled: true }
  },
  {
    key: 'dealer',
    name: 'dealer',
    label: 'Dealer',
    controlBy: 'userType',
    fieldProps: { disabled: true }
  },
  {
    key: 'rank',
    name: 'rank',
    type: fieldType.READONLY_TEXT,
    label: 'Rank',
    errorField: 'rank',
    fieldProps: {
      type: 'text',
      size: 'small'
    }
  },
  {
    key: 'companyName',
    name: 'companyName',
    type: fieldType.READONLY_TEXT,
    label: 'Company',
    errorField: 'companyName',
    fieldProps: {
      type: 'text',
      size: 'small'
    }
  },
  {
    key: 'dealerAddress',
    name: 'dealerAddress',
    type: fieldType.READONLY_TEXT,
    label: 'Workspace',
    errorField: 'dealerAddress',
    fieldProps: {
      type: 'text',
      size: 'small'
    }
  },
  {
    key: 'address',
    name: 'address',
    type: fieldType.READONLY_TEXT,
    label: 'Customer Address',
    errorField: 'address',
    fieldProps: {
      type: 'text',
      size: 'small'
    }
  },
  {
    key: 'point',
    name: 'point',
    type: fieldType.NUMBER,
    label: 'Point',
    errorField: 'point',
    fieldProps: {
      type: 'text',
      size: 'small'
    }
  },
  {
    key: 'grade',
    name: 'grade',
    type: fieldType.TEXT,
    label: 'Grade',
    errorField: 'grade',
    fieldProps: {
      type: 'text',
      size: 'small'
    }
  }
];
