class PrivateStore {
  constructor() {
    this._token = null;
  }

  setToken = (token) => {
    this._token = token;
  };

  getToken = () => this._token;

  removeToken = () => {
    this._token = null;
  };
}

const privateStore = new PrivateStore();

export default privateStore;
