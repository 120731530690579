import { yupResolver } from '@hookform/resolvers/yup';
import { Container, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import FormLayout from 'src/components/FormLayout';

const defaultValues = {
  name: '',
  priority: ''
};

const getInitialValues = (initialValues) => {
  if (!initialValues) {
    return defaultValues;
  }

  return { ...initialValues, priority: initialValues.priority };
};

const validateSchema = yup
  .object({
    name: yup.string().required('Name is required.')
  })
  .required();

CategoryForm.propTypes = {
  initialValues: PropTypes.array,
  categoryName: PropTypes.string,
  cancelButtonProps: PropTypes.object,
  submitButtonProps: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

CategoryForm.defaultProps = {
  initialValues: null,
  categoryName: undefined,
  cancelButtonProps: {},
  submitButtonProps: {},
  onSubmit: () => null,
  onCancel: () => null
};
export default function CategoryForm({
  initialValues,
  categoryName,
  cancelButtonProps,
  submitButtonProps,
  onSubmit,
  onCancel
}) {
  const {
    setValue,
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    defaultValues: getInitialValues(initialValues),
    resolver: yupResolver(validateSchema)
  });

  const _handleChangePriority = (event) => {
    const { value } = event.target;
    setValue('priority', value);
  };

  return (
    <FormLayout
      title={`${initialValues !== null ? 'Edit ' : 'New '} ${categoryName}`}
      cancelButtonProps={{ ...cancelButtonProps, onClick: onCancel }}
      submitButtonProps={{ ...submitButtonProps, onClick: handleSubmit(onSubmit) }}
    >
      <Container sx={{ paddingY: '16px' }}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Name"
              fullWidth
              error={Boolean(errors.name)}
              required
            />
          )}
        />

        <Controller
          name="priority"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Display Order"
              fullWidth
              type="number"
              onChange={_handleChangePriority}
              style={{ marginTop: '16px' }}
              error={Boolean(errors.name)}
            />
          )}
        />
      </Container>
    </FormLayout>
  );
}
