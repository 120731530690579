/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Stack, Avatar, Button, Container, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import Table from 'src/components/Table';
import RightDrawer from 'src/components/RightDrawer';
import Page from 'src/components/Page';
import Iconify from 'src/components/Iconify';
import brandService from 'src/services/brand';
import LoadImage from 'src/components/LoadImage';
import BrandForm from './components/BrandForm';

const configs = [
  {
    key: 'name',
    name: 'Name',
    cellProps: { component: 'th', scope: 'row', padding: 'none' }
  },
  {
    key: 'logoUrl',
    name: 'Logo',
    render: (row) => (
      <LoadImage
        Component={Avatar}
        alt={row.name}
        src={row.logoUrl}
        imgProps={{ style: { objectFit: 'contain' } }}
        variant="square"
      />
    )
  },
  {
    key: 'priority',
    name: 'Display Order'
  }
];

export default function Car() {
  const [state, setState] = useState({
    page: 0,
    size: 5,
    items: [],
    rowCount: 0,
    filter: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const confirm = useConfirm();
  const [item, setItem] = useState(undefined);

  const { page, size, items, rowCount, filter } = state;

  const _getData = async () => {
    const result = await brandService.listAsync(page, filter, size);
    if (result.data) {
      setState({ ...state, items: result.data.items, rowCount: result.data.totalRow });
    }
  };

  useEffect(() => {
    _getData();
  }, [page, size, filter]);

  const _handleChangePage = (page) => {
    setState({ ...state, page });
  };

  const _handleChangeRowsPerPage = (rowsPerPage) => {
    setState({ ...state, size: rowsPerPage, page: 0 });
  };

  const _handleAddUser = () => {
    setItem(null);
  };

  const _handleEdit = (id) => {
    const car = items.find((x) => x.id === id);
    const clonedCar = JSON.parse(JSON.stringify({ ...car, priority: car.priority || '' }));

    setItem(clonedCar);
  };

  const _handleCancel = (e, reason) => {
    if (((reason === 'escapeKeyDown' || reason === 'backdropClick') && !isSubmitting) || !reason) {
      setItem(undefined);
    }
  };

  const submit = async (data) => {
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append(`name`, data.name);
    formData.append('priority', data.priority);

    if (!item || typeof data.logo === 'object') {
      formData.append(`logo`, data.logo);
    }

    if (item === null) {
      const result = await brandService.createAsync(formData);

      if (!result.error) {
        _getData();
        setItem(undefined);
      }
    } else {
      const result = await brandService.updateAsync(data.id, formData);

      if (!result.error) {
        _getData();
        setItem(undefined);
      }
    }

    setIsSubmitting(false);
  };

  const _handleDelete = async (id) => {
    const result = await brandService.deleteAsync(id);

    if (!result.error) {
      _getData();
    } else {
      const content =
        result.error.data?.message || 'An error has occurred. Please reload and try again.';

      confirm({
        title: <Typography fontWeight="bold">Error</Typography>,
        content,
        cancellationButtonProps: { style: { display: 'none' } }
      });
    }
  };

  const _handleChangeFilter = (value) => {
    setState({ ...state, filter: value, page: 0 });
  };

  return (
    <Page title="Brand | Jessicar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Brand
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={_handleAddUser}
          >
            New brand
          </Button>
        </Stack>

        <Table
          searchPlaceholder="Search brand..."
          data={items}
          configs={configs}
          rowCount={rowCount}
          page={page}
          rowsPerPage={size}
          searchKey={filter}
          onChangePage={_handleChangePage}
          onChangeRowsPerPage={_handleChangeRowsPerPage}
          onChangeSearchKey={_handleChangeFilter}
          onEditRow={_handleEdit}
          onDeleteRow={_handleDelete}
        />

        <RightDrawer open={item !== undefined} onClose={_handleCancel}>
          <BrandForm
            initialValues={item}
            cancelButtonProps={{ disabled: isSubmitting }}
            submitButtonProps={{ loading: isSubmitting }}
            onCancel={_handleCancel}
            onSubmit={submit}
          />
        </RightDrawer>
      </Container>
    </Page>
  );
}
