import { rest } from 'msw';
import account from './account';
import carModel from './carModel';

export const handlers = [
  // rest.get('http://localhost:9000/', (req, res, ctx) => {
  //   const { username } = req.body;
  //   return res(
  //     ctx.json({
  //       id: 'f79e82e8-c34a-4dc7-a49e-9fadc0979fda',
  //       username,
  //       firstName: 'John',
  //       lastName: 'Maverick'
  //     })
  //   );
  // })
  ...carModel,
  ...account
];
