import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import palette from 'src/theme/palette';

export default function FormLayout({
  title,
  cancelButtonProps,
  submitButtonProps,
  children,
  maxWidth,
  width
}) {
  return (
    <Box
      sx={{
        width: width || '100%',
        maxWidth: maxWidth || 500,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography
        variant="h4"
        sx={{ padding: '10px 20px', borderBottom: `1px solid ${palette.grey[400]}` }}
      >
        {title}
      </Typography>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>{children}</Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '20px',
          borderTop: `1px solid ${palette.grey[400]}`
        }}
      >
        {cancelButtonProps && (
          <LoadingButton
            variant="outlined"
            color="inherit"
            sx={{ paddingX: '20px' }}
            {...cancelButtonProps}
          >
            {cancelButtonProps.text || 'Cancel'}
          </LoadingButton>
        )}
        {submitButtonProps && (
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{ paddingX: '20px', marginLeft: '20px' }}
            {...submitButtonProps}
          >
            {submitButtonProps.text || 'Submit'}
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
}

FormLayout.propTypes = {
  title: PropTypes.string.isRequired,
  cancelButtonProps: PropTypes.object,
  submitButtonProps: PropTypes.object,
  children: PropTypes.any,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
