import PropTypes from 'prop-types';
// material
import { visuallyHidden } from '@mui/utils';
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead as MuiTableHead,
  TableSortLabel
} from '@mui/material';

// ----------------------------------------------------------------------

TableHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  configs: PropTypes.array,
  isSelectedAllThisPage: PropTypes.bool,
  onRequestSort: PropTypes.func,
  onSelectAll: PropTypes.func,
  checkable: PropTypes.bool,
  action: PropTypes.bool
};

export default function TableHead({
  order,
  orderBy,
  rowCount,
  configs,
  onRequestSort,
  onSelectAll,
  checkable,
  action,
  isSelectedAllThisPage
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const getCellContent = (key, name, sortable) => {
    if (!sortable) {
      return name;
    }

    return (
      <TableSortLabel
        hideSortIcon
        active={orderBy === key}
        direction={orderBy === key ? order : 'asc'}
        onClick={createSortHandler(key)}
      >
        {name}
        {orderBy === key ? (
          <Box sx={{ ...visuallyHidden }}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        ) : null}
      </TableSortLabel>
    );
  };

  return (
    <MuiTableHead>
      <TableRow>
        {checkable && (
          <TableCell padding="checkbox">
            <Checkbox checked={rowCount > 0 && isSelectedAllThisPage} onChange={onSelectAll} />
          </TableCell>
        )}

        {configs.map(({ key, name, headerCellProps, sortable }) => (
          <TableCell
            key={key}
            sortDirection={orderBy === key ? order : false}
            align="left"
            {...headerCellProps}
          >
            {getCellContent(key, name, sortable)}
          </TableCell>
        ))}

        {action && <TableCell style={{ padding: '8px' }} />}
      </TableRow>
    </MuiTableHead>
  );
}
