// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'car',
    path: '/dashboard/car',
    icon: getIcon('bxs:car'),
    children: [
      {
        title: 'car model',
        path: '/dashboard/car/car-model',
        icon: getIcon('icon-park-twotone:adjacent-item')
      },
      { title: 'car trim', path: '/dashboard/car/car-trim', icon: getIcon('fa6-solid:list-check') }
    ]
  },
  {
    title: 'brand',
    path: '/dashboard/brand',
    icon: getIcon('mdi:home-group')
  },
  {
    title: 'bid',
    path: '/dashboard/bid',
    icon: getIcon('majesticons:money-hand')
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill')
  },
  {
    title: 'Account Brand',
    path: '/dashboard/account-brand',
    icon: getIcon('mdi:account-tie')
  },
  {
    title: 'news',
    path: '/dashboard/news',
    icon: getIcon('fluent:news-20-filled')
  },
  {
    title: 'notification',
    path: '/dashboard/notification',
    icon: getIcon('ant-design:notification-filled')
  },
  {
    title: 'rank',
    path: '/dashboard/rank',
    icon: getIcon('icon-park-outline:ranking-list')
  },
  {
    title: 'setting',
    path: '/dashboard/setting',
    icon: getIcon('uil:setting')
  },
  {
    title: 'banner',
    path: '/dashboard/promotion',
    icon: getIcon('carbon:purchase')
  },
  {
    title: 'category',
    path: '/dashboard/category',
    icon: getIcon('carbon:category'),
    children: [
      {
        title: 'fuel type',
        path: '/dashboard/category/fuel-type',
        icon: getIcon('clarity:fuel-line')
      },
      { title: 'vehicle type', path: '/dashboard/category/car-type', icon: getIcon('carbon:car') }
    ]
  }
];

export default sidebarConfig;
