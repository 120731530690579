import { useCallback, useEffect, useRef, useState } from 'react';
// material
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  styled,
  TextField
} from '@mui/material';

// components
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import CarAutocomplete from 'src/components/CarAutocomplete';
import FilePicker from 'src/components/FilePicker';
import FormLayout from 'src/components/FormLayout';
import imageService from 'src/services/imageService';
import privateStore from 'src/stores/PrivateStore';
import * as yup from 'yup';
import Thumbnail from '../Thumbnail';
import Video from '../Video';
import Editor from './Editor';

/**
 * @typedef {'Text' | 'Video'} NewsType
 */

/**
 * @typedef {object} NewsData
 * @property {string} title
 * @property {string} content
 * @property {NewsType} type
 * @property {string | null} thumbnail
 * @property {string | null} video
 * @property {string[]} images
 * @property {?string} serverURL
 * @property {?string} thumbnailUrl
 * @property {?string} videoURL
 * @property {?string} carId
 */

/**
 * @type {NewsData} defaultValues
 */
const defaultValues = {
  title: '',
  content: '',
  type: 'Text',
  thumbnail: null,
  video: null,
  images: []
};

const CKEditorWrapper = styled(FormControl)(({ error }) => ({
  marginTop: '16px',
  '& .ck-content': {
    minHeight: '200px',
    maxHeight: '300px',
    borderColor: error ? 'red !important' : 'inherit'
  },
  '& .ck-editor': {
    marginTop: '8px'
  }
}));

const getDefaultData = (initialValues) => {
  if (initialValues) {
    return {
      ...initialValues,
      content: initialValues.content
        ? initialValues.content.replaceAll('<params>', `token=${privateStore.getToken()}`)
        : initialValues.content,
      images: initialValues.images ? initialValues.images.split(',') : [],
      thumbnail: initialValues.thumbnailUrl
    };
  }

  return defaultValues;
};

const validationScheme = yup.object().shape(
  {
    title: yup.string().max(100, 'Max length is 100.').required('Title is required.'),
    content: yup.string().required('Content is required.'),
    type: yup.mixed().oneOf(['Text', 'Video']).required('Type is required.'),
    thumbnail: yup.mixed().when('type', {
      is: 'Text',
      then: yup.mixed().required('thumbnails are required for text news')
    }),
    urlLink: yup.string().url(),
    video: yup.mixed().when(['type', 'youtubeUrl'], {
      is: (type, youtubeUrl) => type === 'Video' && !youtubeUrl,
      then: yup.mixed().required('video or youtube url is required for video news')
    }),
    youtubeUrl: yup.string().when(['type', 'video'], {
      is: (type, video) => type === 'Video' && !video,
      then: yup.string().url().required('video or youtube url is required for video news')
    })
  },
  [['video', 'youtubeUrl']]
);

export default function NewsForm({
  initialValues,
  cancelButtonProps,
  submitButtonProps,
  onSubmit,
  onCancel
}) {
  const handleUploadImageSuccessRef = useRef();

  console.log({ initialValues });
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm({
    defaultValues: getDefaultData(initialValues),
    resolver: yupResolver(validationScheme)
  });
  /** @type {NewsType} */
  const type = watch('type');
  const thumbnail = watch('thumbnail');
  const video = watch('video');
  const images = watch('images');

  const _onSubmit = (data) => {
    console.log('onSubmit', data);
    const token = privateStore.getToken();
    let content = data.content.replaceAll(data.serverURL, '<server-url>');
    content = content.replaceAll('token=', '<params');
    content = content.replaceAll(token, '>');
    const images = data.images.filter((img) => content.search(img) !== -1);
    const removedImages = data.images.filter((img) => content.search(img) === -1);
    if (removedImages.length > 0) {
      imageService.delete(removedImages);
    }
    const _data = { ...data, images, content };
    onSubmit(_data);
  };

  const _handleUploadImageSuccess = (uploadedData) => {
    setValue('images', [...images, uploadedData.images[0]]);
    setValue('serverURL', uploadedData.serverURL);
  };

  useEffect(() => {
    handleUploadImageSuccessRef.current = _handleUploadImageSuccess;
  });

  return (
    <FormLayout
      title={initialValues !== null ? 'Edit news' : 'New news'}
      cancelButtonProps={{ ...cancelButtonProps, onClick: onCancel }}
      submitButtonProps={{ ...submitButtonProps, onClick: handleSubmit(_onSubmit) }}
    >
      <Container style={{ padding: '16px 24px' }}>
        <FormControl required error={Boolean(errors.type)}>
          <FormLabel>Type</FormLabel>
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <RadioGroup row {...field}>
                <FormControlLabel value="Text" control={<Radio />} label="Text" />
                <FormControlLabel value="Video" control={<Radio />} label="Video" />
              </RadioGroup>
            )}
          />
        </FormControl>

        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Title"
              fullWidth
              style={{ marginTop: '16px' }}
              error={!!errors.title}
              helperText={errors.title && errors.title.message}
              required
            />
          )}
        />

        <Controller
          name="content"
          control={control}
          render={({ field }) => (
            <CKEditorWrapper fullWidth required error={Boolean(errors.content)}>
              <FormLabel style={{ marginBottom: '8px' }}>Content</FormLabel>
              <Editor
                content={field.value}
                onChange={(v) => field.onChange(v)}
                // onChange={_handleChangeContent}
                // content={content}
                handleUploadImageSuccessRef={handleUploadImageSuccessRef}
              />
            </CKEditorWrapper>
          )}
        />

        <Box style={{ marginTop: '16px' }}>
          <Controller
            name="thumbnail"
            control={control}
            render={({ field }) => (
              <>
                {!thumbnail && (
                  <FilePicker
                    label="Thumbnail"
                    onChange={(e) => {
                      if (!e.target.files?.[0]) return;
                      field.onChange(e.target.files[0]);
                    }}
                    error={Boolean(errors.thumbnail)}
                    accept="image/*"
                    required={type === 'Text'}
                  />
                )}
                {thumbnail && (
                  <Box style={{ marginTop: '16px' }}>
                    <FormControlLabel
                      label="thumbnail"
                      labelPlacement="start"
                      control={<Thumbnail src={thumbnail} onRemove={() => field.onChange(null)} />}
                    />
                  </Box>
                )}
              </>
            )}
          />
        </Box>

        {type === 'Text' && (
          <Box style={{ marginTop: '16px' }}>
            <Controller
              name="urlLink"
              control={control}
              render={({ field }) => (
                <TextField
                  label="URL"
                  {...field}
                  helperText={errors?.urlLink && errors?.urlLink?.message}
                  error={errors?.urlLink}
                  fullWidth
                />
              )}
            />
          </Box>
        )}
        {type === 'Video' && (
          <Box style={{ marginTop: '16px' }}>
            <Controller
              name="youtubeUrl"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Youtube URL"
                  {...field}
                  fullWidth
                  error={errors?.youtubeUrl}
                  helperText={errors?.youtubeUrl && errors?.youtubeUrl?.message}
                />
              )}
            />
          </Box>
        )}

        {type === 'Video' && (
          <Box style={{ marginTop: '16px' }}>
            <Controller
              name="video"
              control={control}
              render={({ field }) => (
                <>
                  <FilePicker
                    label="Video"
                    onChange={(e) => field.onChange(e.target.files[0])}
                    error={Boolean(errors.video)}
                    accept="video/*"
                  />
                  <Box style={{ marginTop: '16px' }}>
                    <Video src={video} onRemove={() => field.onChange(null)} />
                  </Box>
                </>
              )}
            />
          </Box>
        )}

        {/* <Box style={{ marginTop: '16px' }}>
          <CarAutocomplete carId={carId} onChange={_handleSelectCar} label="Related car" />
        </Box> */}
      </Container>
    </FormLayout>
  );
}
