/* eslint-disable react-hooks/exhaustive-deps */
import { LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Container,
  Dialog,
  Grid,
  InputAdornment,
  Slide,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import { boolean } from 'src/utils/formatBoolean';
import { fDateTimeSuffixWithFormat } from 'src/utils/formatTime';
import FormLayout from './FormLayout';
import Iconify from './Iconify';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function DateTimeRangPickerCustom({ handleChange, format, label, placeholder }) {
  const [value, setValue] = useState({
    start: null,
    end: null
  });

  const { start, end } = value;
  const [isOpen, setIsOpen] = useState(false);
  const _openSelectTimeArea = () => {
    setIsOpen(true);
  };

  const _closeDateTimePicker = () => {
    setIsOpen(false);
  };

  const _setStart = (start) => {
    setValue({ ...value, start });
  };
  const _setEnd = (end) => {
    setValue({ ...value, end });
  };

  useEffect(() => {
    if (start !== null || end !== null) {
      handleChange(value);
    }
  }, [value]);
  return (
    <Stack direction="row" style={{ width: 450 }}>
      <TextField
        size="small"
        fullWidth
        type="text"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon="material-symbols:calendar-month-rounded"
                sx={{ color: 'text.disabled' }}
              />
            </InputAdornment>
          )
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          placeholder: placeholder || '',
          readOnly: true,
          value:
            start && end
              ? `${fDateTimeSuffixWithFormat(start, format)} - ${fDateTimeSuffixWithFormat(
                  end,
                  format
                )}`
              : fDateTimeSuffixWithFormat(start, format)
        }}
        onClick={_openSelectTimeArea}
      />
      <Dialog
        open={isOpen}
        onClose={_closeDateTimePicker}
        fullWidth
        TransitionComponent={Transition}
        maxWidth="xs"
      >
        <FormLayout title={label} maxWidth="100%">
          <Container sx={{ paddingY: '16px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container justifyContent="space-between">
                <Stack direction="row" style={{ width: '100%', paddingBottom: 16 }}>
                  <div style={{ marginRight: 10, width: 100 }}>Start at: </div>
                  <MobileDateTimePicker
                    value={start}
                    onChange={(newValue) => _setStart(newValue)}
                    toolbarTitle={<Typography>Start at: </Typography>}
                    inputFormat={format}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        sx={{
                          input: {
                            paddingTop: 1,
                            paddingBottom: 1
                          }
                        }}
                        {...params}
                      />
                    )}
                  />
                </Stack>
                <Stack direction="row" style={{ width: '100%' }}>
                  <div style={{ marginRight: 10, width: 100 }}>End at: </div>
                  <MobileDateTimePicker
                    value={end}
                    inputFormat={format}
                    minDateTime={start}
                    onChange={(newValue) => {
                      _setEnd(newValue);
                    }}
                    toolbarTitle={<Typography>End at: </Typography>}
                    onClose={() => {
                      setIsOpen(false);
                    }}
                    disabled={!boolean(start)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        sx={{
                          input: {
                            paddingTop: 1,
                            paddingBottom: 1
                          }
                        }}
                        {...params}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </LocalizationProvider>
          </Container>
        </FormLayout>
      </Dialog>
    </Stack>
  );
}
