import { debounce } from 'lodash';
// ck-editor
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// service
import privateStore from 'src/stores/PrivateStore';
// components
import { apiUrl } from 'src/constants';
import CKUploadAdapter from './CKUploadAdapter';

const ckConfig = {
  ckfinder: {
    uploadUrl: `${apiUrl}admin-images/upload-file`,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${privateStore.getToken()}`,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Headers': '*'
    }
  },
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      '|',
      'uploadImage',
      'blockQuote',
      'mediaEmbed',
      '|',
      'undo',
      'redo'
    ]
  },
  image: {
    toolbar: [
      'imageStyle:inline',
      'imageStyle:block',
      'imageStyle:side',
      '|',
      'toggleImageCaption',
      'imageTextAlternative'
    ]
  },
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
  },
  language: 'vi'
};

export default function Editor({ content, onChange, handleUploadImageSuccessRef }) {
  const _handleChangeContent = debounce((e, editor) => {
    onChange(editor.getData());
  }, 300);

  return (
    <CKEditor
      data={content}
      editor={ClassicEditor}
      onChange={_handleChangeContent}
      config={ckConfig}
      onReady={(editor) => {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
          new CKUploadAdapter(loader, handleUploadImageSuccessRef);
      }}
    />
  );
}

Editor.defaultProps = {
  content: '',
  onChange: () => null
};
