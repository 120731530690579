/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  Slide,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import * as d3 from 'd3';
import { useConfirm } from 'material-ui-confirm';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import Table from 'src/components/Table';
import { carOption, fieldType } from 'src/constants';
import { userFormSchema } from 'src/constants/FormSchema';
import carService from 'src/services/car';
import userService from 'src/services/user';
import { fDateSql, fDateTime } from 'src/utils/formatTime';
import StatChart from './Components/StatChart';
import UserForm from './Components/UserForm';

function StatCounts({ statCounts }) {
  const dataPerType = useMemo(() => d3.group(statCounts, (d) => d.countType), [statCounts]);
  return (
    <Stack direction="row" gap="8px" mb="8px">
      {Array.from(dataPerType).map(([key, value]) => (
        <Box key={key} borderRadius="8px" bgcolor="white" p="10px">
          <StatChart data={value} type={key} />
        </Box>
      ))}
    </Stack>
  );
}

const configs = (viewDetails) => [
  {
    key: 'appId',
    name: 'App ID',
    render: (row) => (
      <Tooltip title="View account details">
        <Button color="primary" variant="text" onClick={() => viewDetails(row)}>
          {row.appId}
        </Button>
      </Tooltip>
    )
  },
  {
    key: 'nickName',
    name: 'Nick Name'
  },
  {
    key: 'fullName',
    name: 'Name'
  },
  {
    key: 'registeredDate',
    name: 'Member Since',
    render: (row) => <p>{fDateTime(row.registeredDate)}</p>
  },
  {
    key: 'birthday',
    name: 'Birthday',
    render: (row) => <p>{row.birthday && fDateTime(row.birthday)}</p>
  },
  {
    key: 'customer',
    name: 'Customer',
    render: (row) => (
      <IconButton style={{ fontSize: 36 }} color={row.customer ? 'success' : 'error'}>
        <Iconify icon={row.customer ? 'material-symbols:check-small-rounded' : 'ic:round-close'} />
      </IconButton>
    )
  },
  {
    key: 'dealer',
    name: 'Dealer',
    render: (row) => (
      <IconButton style={{ fontSize: 36 }} color={row.dealer ? 'success' : 'error'}>
        <Iconify icon={row.dealer ? 'material-symbols:check-small-rounded' : 'ic:round-close'} />
      </IconButton>
    )
  },
  {
    key: 'authorized',
    name: 'Status',
    render: ({ authorized }) => (authorized ? 'Authorized' : 'Unauthorized')
  },
  {
    key: 'brand',
    name: 'Brands'
  },
  {
    render: ({ appId }) => (
      <Tooltip title="View account details">
        <Link to="/dashboard/account-brand" state={{ appId }} style={{ textDecoration: 'none' }}>
          View Brands
        </Link>
      </Tooltip>
    )
  }
];

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function User() {
  const [listBrand, setListBrand] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const moreFilterOptions = [
    {
      key: 'userType',
      type: fieldType.SELECT,
      listOption: [
        { id: 2, name: 'Customer/Dealer' },
        { id: 0, name: 'Customer' },
        { id: 1, name: 'Dealer' }
      ],
      fieldProps: {
        placeholder: 'Filter by user type',
        fullWidth: true
      }
    },
    {
      key: 'userStatus',
      type: fieldType.SELECT,
      listOption: [
        { id: 2, name: 'Authorized/Unauthorized' },
        { id: 1, name: 'Authorized' },
        { id: 0, name: 'Unauthorized' }
      ],
      fieldProps: {
        placeholder: 'Filter by status',
        fullWidth: true
      }
    },
    {
      key: 'member',
      type: fieldType.DATE_RANG_PICKER,
      fieldProps: {
        placeholder: 'Member since',
        fullWidth: true,
        maxDate: new Date()
      }
    },
    {
      key: 'birth',
      type: fieldType.DATE_RANG_PICKER,
      fieldProps: {
        placeholder: 'Birthday',
        fullWidth: true,
        maxDate: new Date()
      }
    },
    {
      key: 'brandId',
      type: fieldType.SELECT,
      listOption: listBrand,
      fieldProps: {
        placeholder: 'Filter by brand',
        fullWidth: true
      }
    }
  ];

  const [item, setItem] = useState(undefined);
  const confirm = useConfirm();
  const [statCounts, setStatCounts] = useState([]);

  const [state, setState] = useState({
    page: 0,
    size: 5,
    items: [],
    rowCount: 0,
    filter: '',
    userType: 2,
    userStatus: 2,
    startMember: '',
    endMember: '',
    startBirth: '',
    endBirth: '',
    brandId: ''
  });

  const {
    page,
    size,
    items,
    rowCount,
    filter,
    userType,
    userStatus,
    startMember,
    endMember,
    startBirth,
    endBirth,
    brandId
  } = state;

  const _getData = async () => {
    const params = {
      page,
      keyword: filter,
      size,
      userType,
      userStatus,
      startMember,
      endMember,
      startBirth,
      endBirth,
      brandId
    };
    const result = await userService.listAsync(params);
    if (result.data) {
      setState({ ...state, items: result.data.items, rowCount: result.data.totalRow });
    }
    const reqUserStats = await userService.statusCountAsync();
    if (reqUserStats.data) {
      setStatCounts(reqUserStats.data);
    }
  };

  const _getListBrand = async () => {
    const result = await carService.listOptionsAsync(carOption.brand);
    setListBrand(result.data);
  };

  useEffect(() => {
    _getData();
  }, [
    page,
    size,
    filter,
    userType,
    userStatus,
    startMember,
    endMember,
    startBirth,
    endBirth,
    brandId
  ]);

  useEffect(() => {
    _getListBrand();
  }, []);

  const _handleChangeOrder = (orderBy, order) => {
    setState({ ...state, orderBy, order });
  };

  const _handleChangePage = (page) => {
    setState({ ...state, page });
  };

  const _handleChangeRowsPerPage = (rowsPerPage) => {
    setState({ ...state, size: rowsPerPage, page: 0 });
  };

  const _handleChangeFilter = (value) => {
    setState({ ...state, filter: value, page: 0 });
  };

  const _handleChangeSelect = (value, property) => {
    setState({ ...state, [property]: value, page: 0 });
  };

  const _handleChangeDate = (value, property) => {
    if (Array.isArray(value)) {
      const uppercaseProperty = property.charAt(0).toUpperCase() + property.slice(1);
      const startDate = fDateSql(value[0]);
      const endDate = value[1] === null ? fDateSql(new Date()) : fDateSql(value[1]);
      setState({
        ...state,
        [`start${uppercaseProperty}`]: startDate,
        [`end${uppercaseProperty}`]: endDate,
        page: 0
      });
    }
  };

  const _handleCancel = (_e, reason) => {
    if (((reason === 'escapeKeyDown' || reason === 'backdropClick') && !isSubmitting) || !reason) {
      setItem(undefined);
    }
  };

  const _handleView = (item) => {
    setItem(item);
  };

  const submit = async (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('nickName', data.nickName);
    formData.append('grade', data.grade);
    formData.append('point', data.point);

    const result = await userService.updateAsync(data.id, formData);

    if (!result.error) {
      _getData();
    } else {
      const content =
        result.error.data?.message || 'An error has occurred. Please reload and try again.';

      confirm({
        title: <Typography fontWeight="bold">Error</Typography>,
        content,
        cancellationButtonProps: { style: { display: 'none' } }
      });
    }
    setItem(undefined);
    setIsSubmitting(false);
  };

  return (
    <Page title="User | Jessicar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
        </Stack>

        <StatCounts statCounts={statCounts} />

        <Table
          searchPlaceholder="Search by ID/name..."
          moreFilterOptions={moreFilterOptions}
          handleChangeSelect={_handleChangeSelect}
          handleChangeDateRang={_handleChangeDate}
          data={items}
          configs={configs(_handleView)}
          rowCount={rowCount}
          page={page}
          rowsPerPage={size}
          onChangeOrder={_handleChangeOrder}
          searchKey={filter}
          onChangePage={_handleChangePage}
          onChangeRowsPerPage={_handleChangeRowsPerPage}
          onChangeSearchKey={_handleChangeFilter}
          action={false}
        />

        <Dialog
          fullWidth
          maxWidth="lg"
          open={item !== undefined}
          TransitionComponent={Transition}
          onClose={_handleCancel}
        >
          <UserForm
            title="Account information"
            initialValues={item}
            formSchema={userFormSchema}
            cancelButtonProps={{ disabled: isSubmitting }}
            submitButtonProps={{ loading: isSubmitting }}
            onSubmit={submit}
            onCancel={_handleCancel}
          />
        </Dialog>
      </Container>
    </Page>
  );
}
