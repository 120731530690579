/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Button,
  Container,
  Dialog,
  Slide,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
// import { useConfirm } from 'material-ui-confirm';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import LoadImage from 'src/components/LoadImage';
import Page from 'src/components/Page';
import RightDrawer from 'src/components/RightDrawer';
import Table from 'src/components/Table';
import { carOption, fieldType } from 'src/constants';
import { userFormSchema } from 'src/constants/FormSchema';
import accountBrandService from 'src/services/accountBrand';
import carService from 'src/services/car';
import { useAccountBrandEdit } from 'src/stores/accountBrandEdit';
// import userService from 'src/services/user';
import UserForm from '../user/Components/UserForm';
import ChangeStatusForm from './components/ChangeStatusForm';

const configs = (viewUser) => [
  {
    key: 'appId',
    name: 'App ID',
    render: (row) => (
      <Stack spacing={2} direction="row">
        <Tooltip title={`View ${row.fullName || ''}`}>
          <Button variant="text" onClick={() => viewUser(row)}>
            {row.appId}
          </Button>
        </Tooltip>
      </Stack>
    )
  },
  {
    key: 'fullName',
    name: 'Full Name'
  },
  {
    key: 'nickName',
    name: 'Nick Name'
  },
  {
    key: 'brand',
    name: 'Brand Name'
  },
  {
    key: 'businessCard',
    name: 'Business Card',
    render: ({ name, businessCard }) => (
      <LoadImage
        Component={Avatar}
        alt={name}
        src={businessCard}
        imgProps={{ style: { objectFit: 'contain' } }}
        variant="square"
        clickable
      />
    )
  },
  {
    key: 'status',
    name: 'Status',
    render: (row) => (
      <Tooltip title="Change business card status">
        <Button
          variant="text"
          color="inherit"
          endIcon={<Iconify icon="material-symbols:arrow-drop-down" />}
        >
          {row.status}
        </Button>
      </Tooltip>
    )
  },
  {
    key: 'subscriptionStatus',
    name: 'Subscription Mode',
    render: (row) => (row.subscriptionStatus ? 'Subscribed' : 'Unsubscribed')
  },
  { key: 'subscriptionName', name: 'Subscription Period' },
  { key: 'startDate', name: 'Start Date' },
  { key: 'endDate', name: 'End Date' }
];

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function AccountBrand() {
  const local = useLocation();
  // const confirm = useConfirm();

  const { appId, brdId } = local?.state || {};

  const [searchParams, setSearchParams] = useSearchParams();
  const accountBrandId = useMemo(() => searchParams.get('account-brand-id'), [searchParams]);
  const [listBrand, setListBrand] = useState([]);
  const { setAccountBrand } = useAccountBrandEdit();
  // const [item, setItem] = useState(undefined);
  const [userDetail, setUserDetail] = useState(undefined);
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, setState] = useState({
    page: 0,
    size: 5,
    items: [],
    rowCount: 0,
    filter: appId || '',
    status: appId ? '0' : '',
    brandId: brdId && listBrand.length > 0 ? brdId : ''
  });

  const { page, size, items, rowCount, filter, status, brandId } = state;

  const moreFilterOptions = [
    {
      key: 'status',
      type: fieldType.SELECT,
      listOption: [
        { id: '', name: 'All' },
        { id: '0', name: 'New' },
        { id: '1', name: 'Accepted' },
        { id: '2', name: 'Rejected' }
      ],
      fieldProps: {
        placeholder: 'Filter by status',
        defaultValue: status,
        fullWidth: true
      }
    },
    {
      key: 'brandId',
      type: fieldType.SELECT,
      listOption: listBrand,
      fieldProps: {
        placeholder: 'Filter by brand',
        fullWidth: true,
        defaultValue: brandId
      }
    }
  ];

  const _getListBrand = async () => {
    const result = await carService.listOptionsAsync(carOption.brand);
    const listBrand = [{ id: '', name: 'All' }].concat(result.data);
    setListBrand(listBrand);
  };

  const _getData = async () => {
    const result = await accountBrandService.listAsync(filter, status, brandId, size, page);
    setState({ ...state, items: result.data.items, rowCount: result.data.totalRow });
  };

  useEffect(() => {
    _getListBrand();
  }, []);

  useEffect(() => {
    setState({ ...state, brandId: brdId && listBrand.length > 0 ? brdId : '' });
  }, [listBrand]);

  useEffect(() => {
    _getData();
  }, [page, size, filter, status, brandId]);

  const _handleChangeSelect = (value, property) => {
    setState({ ...state, [property]: value, page: 0 });
  };
  const _handleChangeOrder = (orderBy, order) => {
    setState({ ...state, orderBy, order });
  };

  const _handleChangePage = (page) => {
    setState({ ...state, page });
  };

  const _handleChangeRowsPerPage = (rowsPerPage) => {
    setState({ ...state, size: rowsPerPage, page: 0 });
  };

  const _handleChangeFilter = (value) => {
    setState({ ...state, filter: value, page: 0 });
  };

  const _handleViewUserDetail = async (item) => {
    const { userId } = item;
    setSearchParams({ 'account-brand-id': userId });
    // setUserDetail(result.data);
  };

  // const _handleCancelViewUser = (_e, reason) => {
  //   if (reason === 'escapeKeyDown' || reason === 'backdropClick' || !reason) {
  //     setUserDetail(undefined);
  //   }
  // };

  // const _handleChangeStatusCancel = (_e, reason) => {
  //   if (((reason === 'escapeKeyDown' || reason === 'backdropClick') && !isSubmitting) || !reason) {
  //     setItem(undefined);
  //   }
  // };

  // const _handelEdit = (id) => {
  //   const clonedAccountBrand = items.find((x) => x.id === id);
  //   console.log(clonedAccountBrand);
  //   setItem(clonedAccountBrand);
  // };

  return (
    <Page title="Account Brand | Jessicar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Account Brand
          </Typography>
        </Stack>
        <Table
          searchPlaceholder="Search by ID/name..."
          moreFilterOptions={moreFilterOptions}
          handleChangeSelect={_handleChangeSelect}
          data={items}
          configs={configs(_handleViewUserDetail)}
          rowCount={rowCount}
          page={page}
          rowsPerPage={size}
          onChangeOrder={_handleChangeOrder}
          searchKey={filter}
          onChangePage={_handleChangePage}
          onChangeRowsPerPage={_handleChangeRowsPerPage}
          onChangeSearchKey={_handleChangeFilter}
          action
          deletable={false}
          onEditRow={(id) => {
            const user = items?.find((r) => r.id === id) ?? {};
            setAccountBrand(user);
            console.log(user);
            setSearchParams({ 'account-brand-id': user?.userId });
          }}
        />
        <Dialog
          fullWidth
          maxWidth="lg"
          open={userDetail !== undefined}
          TransitionComponent={Transition}
          onClose={() => setSearchParams({})}
        >
          <UserForm
            title="Account information"
            initialValues={userDetail}
            formSchema={userFormSchema}
            cancelButtonProps
            onCancel={() => setSearchParams({})}
            hideListBrand
          />
        </Dialog>

        <RightDrawer open={!!accountBrandId} onClose={() => setSearchParams({})}>
          <ChangeStatusForm
            // cancelButtonProps={{ disabled: isSubmitting }}
            // submitButtonProps={{ loading: isSubmitting }}
            // onCancel={_handleChangeStatusCancel}
            // onSubmit={_onSubmitChange}
            onRefresh={_getData}
          />
        </RightDrawer>
      </Container>
    </Page>
  );
}
