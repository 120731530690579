/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Container, Stack, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import RightDrawer from 'src/components/RightDrawer';
import Table from 'src/components/Table';
import { categoryType } from 'src/constants';
import categoryService from 'src/services/category';
import CategoryForm from './components/CategoryForm';

const configs = [
  {
    key: 'name',
    name: 'Name',
    cellProps: { component: 'th', scope: 'row', padding: 'none' }
  },
  {
    key: 'priority',
    name: 'Display Order'
  }
];

export default function Category() {
  const { category } = useParams();
  const confirm = useConfirm();
  const [item, setItem] = useState(undefined);
  const [state, setState] = useState({
    page: 0,
    size: 5,
    items: [],
    rowCount: 0,
    filter: '',
    type: category
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { page, size, items, rowCount, filter, type } = state;

  const _getData = async () => {
    const { data } = await categoryService.listAsync(filter, category, page, size);
    if (data) {
      setState({ ...state, items: data.items, rowCount: data.totalRow });
    }
  };

  useEffect(() => {
    setState({
      ...state,
      type: category,
      page: 0,
      filter: ''
    });
  }, [category]);

  useEffect(() => {
    _getData();
  }, [page, size, rowCount, filter, type]);

  const _handleChangePage = (page) => {
    setState({ ...state, page });
  };

  const _handleChangeRowsPerPage = (rowsPerPage) => {
    setState({ ...state, size: rowsPerPage, page: 0 });
  };

  const _handleAddUser = () => {
    setItem(null);
  };

  const _handleEdit = (id) => {
    const cate = items.find((x) => x.id === id);

    const clonedCar = JSON.parse(JSON.stringify({ ...cate, priority: cate.priority || '' }));

    setItem(clonedCar);
  };

  const _handleChangeFilter = (value) => {
    setState({ ...state, filter: value, page: 0 });
  };

  const _handleDelete = async (id) => {
    const result = await categoryService.deleteAsync(id, type);

    if (!result.error) {
      _getData();
    } else {
      const content =
        result.error?.data.message || 'An error has occurred. Please reload and try again.';
      confirm({
        title: <Typography fontWeight="bold">Error</Typography>,
        content,
        cancellationButtonProps: { style: { display: 'none' } }
      });
    }
  };

  const _handleCancel = (e, reason) => {
    if (((reason === 'escapeKeyDown' || reason === 'backdropClick') && !isSubmitting) || !reason) {
      setItem(undefined);
    }
  };

  const submit = async (data) => {
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('priority', data.priority || '');

    const result =
      item === null
        ? await categoryService.createAsync(formData, type)
        : await categoryService.updateAsync(data.id, formData, type);

    if (!result.error) {
      _getData();
      setItem(undefined);
    }

    setIsSubmitting(false);
  };

  return (
    <Page title={`${categoryType[category].title} | Jessicar`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {categoryType[category].title}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={_handleAddUser}
          >
            New {categoryType[category].title}
          </Button>
        </Stack>

        <Table
          searchPlaceholder="Search..."
          data={items}
          configs={configs}
          rowCount={rowCount}
          page={page}
          rowsPerPage={size}
          searchKey={filter}
          onChangePage={_handleChangePage}
          onChangeRowsPerPage={_handleChangeRowsPerPage}
          onChangeSearchKey={_handleChangeFilter}
          onEditRow={_handleEdit}
          onDeleteRow={_handleDelete}
        />

        <RightDrawer open={item !== undefined} onClose={_handleCancel}>
          <CategoryForm
            initialValues={item}
            categoryName={categoryType[category].title}
            cancelButtonProps={{ disabled: isSubmitting }}
            submitButtonProps={{ loading: isSubmitting }}
            onCancel={_handleCancel}
            onSubmit={submit}
          />
        </RightDrawer>
      </Container>
    </Page>
  );
}
