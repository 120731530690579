// material
import { Container, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormLayout from 'src/components/FormLayout';
import LoadImage from 'src/components/LoadImage';
import Image from 'src/components/Image';
import FilePicker from 'src/components/FilePicker';

const defaultValues = {
  name: '',
  logo: null,
  priority: ''
};

const validateSchema = yup
  .object({
    name: yup.string().required('Name is required.'),
    logo: yup.mixed().required('Please select a logo for brand.')
  })
  .required();

const getInitialValues = (initialValues) => {
  if (!initialValues) {
    return defaultValues;
  }

  return { ...initialValues, logo: initialValues.logoUrl, priority: initialValues.priority };
};

export default function BrandForm({
  initialValues,
  cancelButtonProps,
  submitButtonProps,
  onSubmit,
  onCancel
}) {
  const {
    setValue,
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    defaultValues: getInitialValues(initialValues),
    resolver: yupResolver(validateSchema)
  });

  const _handleSelectLogo = (e) => {
    setValue('logo', e.target.files[0]);
  };

  const _handleRemoveLogo = () => {
    setValue('logo', null);
  };

  const renderImage = (src) => {
    if (!src) {
      return null;
    }

    if (typeof src === 'object') {
      return (
        <LoadImage
          Component={Image}
          file={src}
          alt={src.name}
          onRemove={_handleRemoveLogo}
          style={{ margin: '10px' }}
        />
      );
    }

    return (
      <LoadImage
        Component={Image}
        src={src}
        alt={src}
        onRemove={_handleRemoveLogo}
        style={{ margin: '10px' }}
      />
    );
  };

  const _handleChangePriority = (event) => {
    const { value } = event.target;
    setValue('priority', value);
  };

  return (
    <FormLayout
      title={initialValues !== null ? 'Edit brand' : 'New brand'}
      cancelButtonProps={{ ...cancelButtonProps, onClick: onCancel }}
      submitButtonProps={{ ...submitButtonProps, onClick: handleSubmit(onSubmit) }}
    >
      <Container sx={{ paddingY: '16px' }}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Name"
              fullWidth
              error={Boolean(errors.name)}
              required
            />
          )}
        />

        <FilePicker
          label="Brand logo"
          onChange={_handleSelectLogo}
          error={Boolean(errors.logo)}
          accept="image/*"
          required
        />
        <Controller
          name="logo"
          control={control}
          render={({ field }) => renderImage(field.value)}
        />

        <Controller
          name="priority"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Display Order"
              fullWidth
              type="number"
              onChange={_handleChangePriority}
              style={{ marginTop: '16px' }}
              error={Boolean(errors.name)}
            />
          )}
        />
      </Container>
    </FormLayout>
  );
}
