import restServiceHelper from 'src/utils/restServiceHelper';
import restService from './restService';

class ImageService {
  async upload(file) {
    const url = `admin-images/upload-file`;
    const data = new FormData();
    data.append('files', file);

    return restServiceHelper.requestAsync(
      restService.postAsync(url, data, { 'Content-Type': 'multipart/form-data' })
    );
  }

  async delete(files) {
    const url = `admin-images/delete-file`;
    const data = new FormData();
    files.forEach((file) => data.append(`files`, file));

    return restServiceHelper.requestAsync(
      restService.deleteAsync(url, data, { 'Content-Type': 'multipart/form-data' })
    );
  }
}

const imageService = new ImageService();

export default imageService;
