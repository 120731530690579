import { Autocomplete, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import brandService from 'src/services/brand';

export default function BrandAutocomplete({ brandId, onChange, ...props }) {
  const [brands, setBrands] = useState([]);
  const [option, setOption] = useState(null);

  const _handleInputChange = debounce(async (e, v, reason) => {
    if (reason === 'input') {
      const result = await brandService.listAsync(0, v, 20);
      if (!result.error) {
        setBrands(result.data.items.filter((x) => x.id !== v));
      }
    }
  }, 300);

  useEffect(() => {
    if (!brandId) {
      setOption(null);
    } else {
      const _option = brands.find((x) => x.id.toString() === brandId);
      if (_option) {
        setOption(_option);
      } else {
        brandService
          .getAsync(brandId)
          .then((r) => {
            if (r.data) {
              setOption(r.data);
            } else {
              throw new Error('oke');
            }
          })
          .catch(() => setOption({ id: brandId, name: 'Get later' }));
      }
    }
  }, [brandId]);

  return (
    <Autocomplete
      value={option}
      onChange={onChange}
      isOptionEqualToValue={(opt, v) => opt.id === v.id}
      getOptionLabel={(opt) => opt.name}
      options={brands}
      onInputChange={_handleInputChange}
      renderInput={(params) => (
        <TextField {...params} {...props} size="small" value={params.value ?? ''} />
      )}
    />
  );
}
