import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

export default function TextControl({ control, name, ...props }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <TextField className="text-control" {...field} {...props} />}
    />
  );
}
