import { atom } from 'jotai';

const atomAccountBrand = atom({
  status: ''
  // subscription: null,
  // subscriptionName: null,
  // subscriptionStatus: null,
  // subscriptionType: null,
  // businessCard: ''
});

export default atomAccountBrand;
