import { InputAdornment, TextField, Typography } from '@mui/material';
import { LocalizationProvider, MobileDateRangePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import React, { useState } from 'react';
import Iconify from './Iconify';

export default function MobileDateRangePickerCustom({
  handleChange,
  label,
  format,
  placeholder,
  rerenderInput,
  style,
  maxDate,
  ...props
}) {
  const [value, setValue] = useState([null, null]);

  const _handleChange = (e) => {
    setValue(e);
    handleChange(e);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDateRangePicker
        label={label}
        inputFormat={format}
        value={value}
        disableCloseOnSelect={false}
        onChange={_handleChange}
        toolbarTitle={<Typography>{placeholder}</Typography>}
        maxDate={maxDate}
        startText=""
        endText=""
        renderInput={(start, end) => (
          <TextField
            {...start}
            sx={{
              input: {
                paddingTop: 1,
                paddingBottom: 1
              }
            }}
            InputProps={{
              ...start.inputProps,
              endAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="material-symbols:calendar-month-rounded"
                    sx={{ color: 'text.disabled' }}
                  />
                </InputAdornment>
              )
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              ...start.inputProps,
              placeholder: placeholder || '',
              value:
                start.inputProps.value !== '' && end.inputProps.value !== ''
                  ? `${start.inputProps.value} - ${end.inputProps.value}`
                  : start.inputProps.value
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
