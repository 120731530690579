/* eslint-disable no-dupe-class-members */
import axios from 'axios';
import { apiUrl } from 'src/constants';
import privateStore from 'src/stores/PrivateStore';

class RestService {
  _config = (customHeaders) => ({
    headers: {
      ...customHeaders,
      Authorization: `Bearer ${privateStore.getToken()}`,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Headers': '*'
    }
  });

  async getAsync(url, params = {}, options = {}) {
    return axios.get(apiUrl + url, { ...this._config(), params, ...options });
  }

  async postAsync(url, data = {}, headers = {}) {
    return axios.post(
      apiUrl + url,
      data,
      this._config({ 'Content-Type': 'application/json', ...headers })
    );
  }

  async putAsync(url, data = {}) {
    return axios.put(apiUrl + url, data, this._config({ 'Content-Type': 'application/json' }));
  }

  async deleteAsync(url, data, headers = {}) {
    return axios.delete(apiUrl + url, { ...this._config(headers), data });
  }

  async patchAsync(url, data = {}) {
    return axios.patch(apiUrl + url, data, this._config({ 'Content-Type': 'application/json' }));
  }
}

const restService = new RestService();

export default restService;
