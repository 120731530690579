import restServiceHelper from 'src/utils/restServiceHelper';
import restService from './restService';

class SettingService {
  async updateAsync(id, data) {
    const url = `administrator/setting/${id}`;

    return restServiceHelper.requestAsync(restService.putAsync(url, data));
  }

  async getAsync(id = 1) {
    const url = `administrator/setting/${id}`;

    return restServiceHelper.requestAsync(restService.getAsync(url));
  }
}

const settingService = new SettingService();

export default settingService;
