import { Box, Typography } from '@mui/material';
import { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import _ from 'underscore';
import * as d3 from 'd3';

const marginLeft = 10;
const marginRight = 10;
const marginTop = 15;
const marginBottom = 10;

export default function StatChart({ data, type, ...props }) {
  const refSvg = useRef();

  const drawChart = () => {
    if (!refSvg.current) return;
    const svgLayout = refSvg.current.getBoundingClientRect();
    const { width, height } = svgLayout;
    const svg = d3.select(refSvg.current);
    const parsedData = data.map((d) => ({ ...d, date: new Date(d.weekDay) }));
    const extentX = d3.extent(parsedData, (d) => d.date);
    const extentY = d3.extent(parsedData, (d) => d.count);
    const scaleX = d3
      .scaleLinear()
      .domain(extentX)
      .range([marginLeft, width - marginRight]);
    const scaleY = d3
      .scaleLinear()
      .domain(extentY.reverse())
      .range([marginTop, height - marginTop - marginBottom]);
    const _data = parsedData.map((d) => ({ ...d, x: scaleX(d.date), y: scaleY(d.count) }));
    const g = svg.select('g').empty() ? svg.append('g') : svg.select('g');

    const axisScaleX = d3.scaleTime().domain(extentX).range(scaleX.range());
    const axisX = d3
      .axisBottom(axisScaleX)
      .ticks(5)
      .tickFormat((d) => `${d.getMonth()}.${d.getDate()}`);
    // const axisY = d3.axisLeft(scaleY);
    const gAxisX = g.select('.axis-x').empty()
      ? g.append('g').attr('class', 'axis-x')
      : g.select('.axis-x');
    // const gAxisY = g.select('.axis-y').empty()
    //   ? g.append('g').attr('class', 'axis-y')
    //   : g.select('.axis-y');
    gAxisX.attr('transform', `translate(0,${scaleY.range()[1]})`).call(axisX);
    // gAxisY.attr('transform', `translate(${scaleX.range()[0]},0)`).call(axisY);

    const line = g.select('.line').empty()
      ? g.append('path').attr('class', 'line')
      : g.select('.line');

    const lineGen = d3
      .line()
      .x((d) => d.x)
      .y((d) => d.y);
    line.attr('d', () => lineGen(_data));

    g.selectAll('.plot')
      .data(_data, (d) => d.date)
      .join(
        // enter
        (_g) =>
          _g
            .append('circle')
            .attr('class', 'plot')
            .attr('data-date', (d) => d.weekDay)
            .attr('r', 2)
            .attr('transform', (d) => `translate(${d.x},${d.y})`),
        // update
        (_g) =>
          _g
            .attr('data-date', (d) => d.weekDay)
            .attr('transform', (d) => `translate(${d.x},${d.y})`),
        // exit
        (_g) => _g.remove()
      );

    g.selectAll('.label')
      .data(_data, (d) => d.data)
      .join(
        (_g) =>
          // enter
          _g
            .append('g')
            .attr('class', 'label')
            .attr('data-date', (d) => d.weekDay)
            .attr('transform', (d) => `translate(${d.x},${d.y})`)
            .call((gLabel) => gLabel.append('text').text((d) => d.count)),
        // update
        (_g) =>
          _g
            .attr('data-date', (d) => d.weekDay)
            .attr('transform', (d) => `translate(${d.x},${d.y})`)
            .call((gLabel) => gLabel.select('text').text((d) => d.count)),
        // remove
        (_g) => _g.remove()
      );
  };
  const onResize = () => {
    drawChart();
  };
  const onResizeDebounced = useMemo(() => _.debounce(onResize, 200, false), []);
  const resizeObserver = useMemo(() => new ResizeObserver(onResizeDebounced));
  useLayoutEffect(() => {
    if (refSvg.current) {
      resizeObserver.observe(refSvg.current);
      return () => resizeObserver.unobserve(refSvg.current);
    }
  }, []);
  useEffect(() => {
    if (onResizeDebounced) onResizeDebounced();
  }, [data, type]);

  return (
    <Box className="stat-chart" {...props}>
      <Typography>{type} users</Typography>
      <Box
        component="svg"
        ref={refSvg}
        data-type={type}
        sx={{
          width: '100%',
          '.plot': {
            fill: 'red'
          },
          '.line': {
            fill: 'none',
            stroke: 'red',
            strokeWidth: '1px'
          },
          '.label': {
            textAnchor: 'middle',
            fontSize: '12px',
            text: {
              transform: 'translate(0,-5px)'
            }
          },
          '&[data-type="subscription"]': {
            '.plot': { fill: 'green' },
            '.line': { stroke: 'green' }
          },
          '&[data-type="verified"]': {
            '.plot': { fill: 'blue' },
            '.line': { stroke: 'blue' }
          }
        }}
      ></Box>
    </Box>
  );
}
