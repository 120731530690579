import restServiceHelper from 'src/utils/restServiceHelper';
import restService from './restService';

/**
 * @typedef {Object} StatusCount
 * @property {number} complet
 * @property {number} contracted
 * @property {number} dealing
 * @property {number} total
 */

/**
 * @typedef {Object} StatusCountRespose
 * @property {StatusCount} data
 */

class BidService {
  async listAsync(page = 0, keyword = '', size = 5) {
    const url = `administrator/bid?page=${page}&keyword=${keyword}&size=${size}`;
    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  /**
   * @description 비딩 집계 대시보드 화면
   * @returns {Promise<StatusCountRespose>}
   */
  async statusCount() {
    const url = 'administrator/bid/status/count';
    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  /**
   * @description 채팅 정보 가져오기
   */
  async chatHistory(bidId = 0) {
    const url = `administrator/bid/${bidId}/chat`;
    return restServiceHelper.requestAsync(restService.getAsync(url));
  }

  async downloadExcel(keyWord) {
    const url = `administrator/bid/excel/download`;
    return restServiceHelper.requestAsync(
      restService.getAsync(url, { keyWord }, { responseType: 'blob' })
    );
  }

  async uploadExcel(formData) {
    const url = 'administrator/bid/excel/upload';
    return restServiceHelper.requestAsync(
      restService.postAsync(url, formData, { 'Content-Type': 'multipart/form-data' })
    );
  }
}

const bidService = new BidService();

export default bidService;
