import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Grid,
  Stack
} from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { debounce } from 'lodash';
import { fieldType } from 'src/constants';
import Iconify from '../Iconify';
import Select from '../Select';
import MobileDateRangePickerCustom from '../MobileDateRangePickerCustom';
import DateTimeRangPickerCustom from '../DateTimeRangPickerCustom';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

TableToolbar.propTypes = {
  numSelected: PropTypes.number,
  searchKey: PropTypes.string,
  onChangeSearchKey: PropTypes.func,
  onDeleteAll: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  handleChangeSelect: PropTypes.func,
  handleChangeDateRang: PropTypes.func,
  handleChangeDateTimeRang: PropTypes.func,
  moreFilterOptions: PropTypes.array,
  FilterComponent: PropTypes.any,
  hideFilter: PropTypes.bool,
  hidePagination: PropTypes.bool
};

export default function TableToolbar({
  numSelected,
  searchKey,
  onChangeSearchKey,
  onDeleteAll,
  searchPlaceholder,
  handleChangeSelect,
  handleChangeDateRang,
  handleChangeDateTimeRang,
  moreFilterOptions,
  FilterComponent,
  hideFilter
}) {
  const confirm = useConfirm();

  const _handleDeleteAll = () => {
    confirm({
      title: `Are your sure you want to delete this record${numSelected > 1 ? 's' : ''}?`
    }).then(onDeleteAll);
  };

  const _handleChangeSelect = (value, property) => {
    handleChangeSelect(value, property);
  };

  const _handleChangeDateRang = (value, property) => {
    handleChangeDateRang(value, property);
  };
  const _handleChangeDateTimeRang = (value, property) => {
    handleChangeDateTimeRang(value, property);
  };

  const _handleChange = debounce(onChangeSearchKey, 300);

  return (
    <Stack>
      <RootStyle
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main',
            bgcolor: 'primary.lighter'
          })
        }}
      >
        {numSelected > 0 && (
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        )}
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton onClick={_handleDeleteAll} color="error">
              <Iconify icon="eva:trash-2-fill" />
            </IconButton>
          </Tooltip>
        )}
      </RootStyle>
      {!hideFilter && (
        <Grid container justifyContent="start">
          <Stack
            direction="row"
            spacing={2}
            style={{
              border: '1px solid #b3b3b3',
              borderRadius: 5,
              margin: 5
            }}
          >
            <SearchStyle
              size="small"
              defaultValue={searchKey}
              onChange={_handleChange}
              placeholder={searchPlaceholder}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
              fullWidth
            />
          </Stack>

          {moreFilterOptions.map((filter, key) => {
            switch (filter.type) {
              case fieldType.SELECT:
                return (
                  <Stack
                    key={key}
                    direction="row"
                    spacing={2}
                    style={{
                      margin: 5
                    }}
                  >
                    <Select
                      options={filter.listOption}
                      getValue={(opt) => opt.id}
                      getLabel={(opt) => opt.name}
                      onChange={({ target: { value } }) => _handleChangeSelect(value, filter.key)}
                      style={{
                        width: 240,
                        height: '100%'
                      }}
                      {...filter.fieldProps}
                    />
                  </Stack>
                );

              case fieldType.DATE_RANG_PICKER:
                return (
                  <Stack
                    key={key}
                    direction="row"
                    spacing={2}
                    style={{
                      margin: 5
                    }}
                  >
                    <MobileDateRangePickerCustom
                      format="dd/MM/yyyy"
                      handleChange={(e) => _handleChangeDateRang(e, filter.key)}
                      maxDate={filter.fieldProps.maxDate}
                      style={{
                        width: 240,
                        height: '100%'
                      }}
                      {...filter.fieldProps}
                    />
                  </Stack>
                );
              case fieldType.DATE_TIME_RANG_PICKER:
                return (
                  <Stack key={key} direction="row" spacing={2} style={{ margin: 5 }}>
                    <DateTimeRangPickerCustom
                      format="dd/MM/yyyy p"
                      handleChange={(e) => _handleChangeDateTimeRang(e, filter.key)}
                      style={{
                        width: 240,
                        height: '100%'
                      }}
                      {...filter.fieldProps}
                    />
                  </Stack>
                );
              default:
                return null;
            }
          })}
        </Grid>
      )}

      {FilterComponent}
    </Stack>
  );
}
