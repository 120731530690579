import { ConfirmProvider } from 'material-ui-confirm';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ConfirmProvider
      defaultOptions={{
        confirmationButtonProps: { variant: 'contained' },
        cancellationButtonProps: { color: 'inherit' }
      }}
    >
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <Router />
      </ThemeConfig>
    </ConfirmProvider>
  );
}
