import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Container, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormLayout from 'src/components/FormLayout';
import optionService from 'src/services/options';
import * as yup from 'yup';

const defaultValues = {
  name: '',
  carId: null,
  rate: '',
  priority: ''
};

const getDefaultValues = (initialValues) => {
  if (!initialValues) {
    return defaultValues;
  }

  return { ...initialValues, carId: { carId: initialValues.carId, name: initialValues.carName } };
};

const validationSchema = yup
  .object()
  .shape({
    name: yup.string().trim().required('Name is required.'),
    carId: yup.mixed().required('Car is required.'),
    rate: yup
      .number()
      .typeError('Rate is required.')
      .min(1, 'Priority must be equal to or more than 1.0.')
      .max(10.0, 'Priority must be less than or equal to 10.0.')
      .required('Rate is required.'),
    priority: yup
      .number()
      .typeError('Priority is required.')
      .min(1, 'Priority must be equal to or more than 1.')
      .required('Priority is required.')
  })
  .required();

export default function RankForm({
  initialValues,
  cancelButtonProps,
  submitButtonProps,
  onSubmit,
  onCancel
}) {
  const [cars, setCars] = useState([]);
  const {
    setValue,
    formState: { errors },
    handleSubmit,
    control,
    getValues
  } = useForm({
    defaultValues: getDefaultValues(initialValues),
    resolver: yupResolver(validationSchema)
  });

  const _handleChangeCar = (e, value) => setValue('carId', value);

  const _handleInputChange = debounce(async (e, value, reason) => {
    if (reason === 'input') {
      const result = await optionService.getTrim(0, value, 20);
      if (!result.error) {
        setCars(result.data.items.filter((x) => x.id !== getValues('id')));
      }
    }
  }, 300);

  return (
    <FormLayout
      title={initialValues !== null ? 'Edit car' : 'New car'}
      cancelButtonProps={{ ...cancelButtonProps, onClick: onCancel }}
      submitButtonProps={{ ...submitButtonProps, onClick: handleSubmit(onSubmit) }}
    >
      <Container sx={{ paddingY: '16px' }}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Name"
              fullWidth
              error={Boolean(errors.name)}
              required
              helperText={Boolean(errors.name) && errors.name.message}
            />
          )}
        />

        <Controller
          name="carId"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              options={cars}
              onInputChange={_handleInputChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Car"
                  sx={{ marginTop: '16px' }}
                  error={Boolean(errors.carId)}
                  value={params.value ?? ''}
                  required
                  helperText={Boolean(errors.carId) && errors.carId.message}
                />
              )}
              onChange={_handleChangeCar}
            />
          )}
        />

        <Controller
          name="rate"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value || ''}
              size="small"
              label="Rate"
              fullWidth
              type="number"
              sx={{ marginTop: '16px' }}
              error={Boolean(errors.rate)}
              required
              helperText={Boolean(errors.rate) && errors.rate.message}
            />
          )}
        />

        <Controller
          name="priority"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value || ''}
              size="small"
              label="Priority"
              fullWidth
              type="number"
              sx={{ marginTop: '16px' }}
              error={Boolean(errors.priority)}
              required
              helperText={Boolean(errors.priority) && errors.priority.message}
            />
          )}
        />
      </Container>
    </FormLayout>
  );
}
