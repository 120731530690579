import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useConfirm } from 'material-ui-confirm';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from './Iconify';

// ----------------------------------------------------------------------

export default function MoreMenu({ id, onEdit, onDelete, deletable, editable }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const confirm = useConfirm();

  const _handleEdit = () => {
    setIsOpen(false);
    onEdit(id);
  };

  const _handleDelete = () => {
    setIsOpen(false);
    confirm({
      title: 'Are your sure you want to delete this record?'
    }).then(() => onDelete(id));
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {deletable && (
          <MenuItem sx={{ color: 'text.secondary' }} onClick={_handleDelete}>
            <ListItemIcon>
              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {editable && (
          <MenuItem sx={{ color: 'text.secondary' }} onClick={_handleEdit}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

MoreMenu.defaultProps = {
  onEdit: () => null,
  onDelete: () => null,
  editable: true,
  deletable: true
};

MoreMenu.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  editable: PropTypes.bool,
  deletable: PropTypes.bool
};
