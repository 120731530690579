import { Drawer } from '@mui/material';
import PropTypes from 'prop-types';

export default function RightDrawer({ open, children, onClose, ...props }) {
  return (
    <Drawer anchor="right" open={open} onClose={onClose} {...props}>
      {children}
    </Drawer>
  );
}

RightDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.any
};
