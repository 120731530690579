export function boolean(value) {
  return !(
    value === false ||
    value === 'false' ||
    value === 'False' ||
    value === 'FALSE' ||
    value === 'no' ||
    value === 'NO' ||
    value === 'No' ||
    value === 'off' ||
    value === 'Off' ||
    value === 'OFF' ||
    value === undefined ||
    value === 'undefined' ||
    value === 'Undefined' ||
    value === 'UNDEFINED' ||
    value === null ||
    value === 'null' ||
    value === 'Null' ||
    value === 'NULL' ||
    value === '' ||
    Number(value) <= 0
  );
}
