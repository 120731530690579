import { Box, MenuItem, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import CarAutocomplete from 'src/components/CarAutocomplete';
import BrandAutocomplete from 'src/components/BrandAutocomplete';

const linkToType = {
  WEBSITE: 1,
  BRAND: 2,
  CAR: 3
};

const linkToTypeText = {
  [linkToType.WEBSITE]: 'Website link',
  [linkToType.BRAND]: 'Cars of brand',
  [linkToType.CAR]: 'Car detail'
};

const getLinkToType = (url = '') => {
  if (!url) {
    return linkToType.WEBSITE;
  }

  if (url.startsWith('http://') || url.startsWith('https://')) {
    return linkToType.WEBSITE;
  }

  if (url.startsWith('car?carId=')) {
    return linkToType.CAR;
  }

  if (url.startsWith('search?brand=')) {
    return linkToType.BRAND;
  }

  return linkToType.WEBSITE;
};

export default function LinkToSelection({ value, onChange }) {
  const [type, setType] = useState(linkToType.WEBSITE);

  const _handleChangeType = (e) => {
    onChange('');
    setType(e.target.value);
  };

  const _handleSelectBrand = (e, v) => onChange(`search?brand=${v.id}`);

  const _handleChangeWebsiteLink = (e) => onChange(e.target.value);

  const _handleSelectCar = (e, v) => onChange(`car?carId=${v.id}`);

  useEffect(() => {
    const _type = getLinkToType(value);
    if (_type !== type) {
      setType(_type);
    }
  }, []);

  return (
    <Box>
      <TextField
        size="small"
        select
        label="Link type"
        value={type}
        onChange={_handleChangeType}
        fullWidth
      >
        {Object.values(linkToType).map((v) => (
          <MenuItem key={v} value={v}>
            {linkToTypeText[v]}
          </MenuItem>
        ))}
      </TextField>
      <Box marginTop="16px">
        {type === linkToType.WEBSITE && (
          <TextField
            fullWidth
            size="small"
            value={value}
            label="Link url"
            onChange={_handleChangeWebsiteLink}
          />
        )}

        {type === linkToType.BRAND && (
          <BrandAutocomplete
            brandId={value ? value.split('=').pop() : null}
            onChange={_handleSelectBrand}
            label="Brand"
          />
        )}

        {type === linkToType.CAR && (
          <CarAutocomplete
            carId={value ? value.split('=').pop() : null}
            onChange={_handleSelectCar}
            label="Car"
          />
        )}
      </Box>
    </Box>
  );
}
